import React from "react";
import { GpdBody } from "components/views/gpd//GpdBody";
import { GpdHeader } from "components/views/gpd/GpdHeader";
import { FullscreenView } from "components/utils/FullscreenView";

export const Gpd = () => <FullscreenView view="GPD" HeaderComponent={GpdHeader} BodyComponent={GpdBody} />;

// export const Gpd = () => {
//   const ref = useRef<DisplayObject>(null);
//   const focused = useFocused(ref);
//   const zStack = useRootSelector(zStackSelector);
//   const dispatch = useRootDispatch();
//   const pos = useRootSelector((state) => viewPositionSelector(state, "GPD"));
//   const dim = useRootSelector((state) => viewDimensionSelector(state, "GPD"));
//   const { startDrag, anyDragging } = useDragging(ref, "GPD", "mouseup");
//   const { isFullscreen, toggleFullscreen } = useFullscreen(ref, "GPD");
//
//   const onmousedownHandler = () =>
//     zStack.indexOf("GPD") < zStack.length - 1 && !isFullscreen && dispatch(pushZStack("GPD"));
//
//   const position = isFullscreen ? {} : pos;
//   const dimension = isFullscreen ? { width: "calc(100% - 10px", height: "calc(100% - 10px)" } : dim;
//
//   return null;
//   // return (
//   //   <div
//   //     ref={ref}
//   //     className={clsx(floatingStyles.resizable, {
//   //       [floatingStyles.isFullscreen]: isFullscreen,
//   //       noPointerEvents: anyDragging,
//   //     })}
//   //     style={{ ...dimension, ...position, zIndex: 100 + zStack.indexOf("GPD") }}
//   //     onmousedown={onmousedownHandler}
//   //   >
//   //     <GpdHeader
//   //       focused={focused}
//   //       toggleFullscreen={toggleFullscreen}
//   //       startDrag={(e) => !isFullscreen && startDrag(e)}
//   //     />
//   //     <GpdBody />
//   //   </div>
//   // );
// };
