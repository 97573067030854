import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { TrialPlan } from "@poscon/shared-types";
import type { RootState } from "~redux/store";

export type TrialPlanState = {
  planQueue: TrialPlan[];
};

const initialState: TrialPlanState = {
  planQueue: [],
};

export const trialPlanSlice = createSlice({
  name: "trialPlan",
  initialState,
  reducers: {
    setPlanState(state, action: PayloadAction<TrialPlanState>) {
      return action.payload;
    },
    setTrialPlanQueue(state, action: PayloadAction<TrialPlan[]>) {
      state.planQueue = action.payload;
    },
    trialPlanCleanup() {
      return initialState;
    },
  },
});

export const { setTrialPlanQueue, trialPlanCleanup } = trialPlanSlice.actions;
export const reducer = trialPlanSlice.reducer;

export const trialPlanQueueSelector = (state: RootState) => state.trialPlan.planQueue;
