import type { ComponentType } from "react";
import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { useDragging } from "hooks/useDragging";
import type { ViewMenuProps } from "components/utils/ViewMenu";
import { ViewMenu } from "components/utils/ViewMenu";
import type { EdstView } from "types/edstView";
import { useZIndex } from "hooks/useZIndex";
import type { EramFontSize, ViewHeaderProps, ViewOptionMapValue } from "@poscon/shared-frontend";
import {
  useViewOptionSelected,
  setSelectedViewOption,
  ViewHeader,
  baseBorderColor,
  eramTextDimensionMap,
  useOnMount,
} from "@poscon/shared-frontend";
import { viewTitleMap } from "~/eramConstants";
import { closeView, pushZStack, viewPositionSelector } from "~redux/slices/edstSlice";
import { useWindowSize } from "usehooks-ts";
import type { RootState } from "~redux/store";
import type { ColorSource, Container as PixiContainer } from "pixi.js";
import { Rectangle } from "pixi.js";
import { Container, Graphics } from "@pixi/react";
import { useViewOptions } from "contexts/viewOptionContext";
import { layerZIndexMap } from "~/layerZIndexMap";
import { ASViewMenu } from "components/utils/ASViewMenu";

type ViewProps<V extends EdstView> = {
  view: V;
  title?: string;
  viewMenuTitle?: string;
  optionMap?: Record<string, ViewOptionMapValue<RootState>>;
  children?: React.ReactNode;
  headerButtons?: ViewHeaderProps["buttons"];
  bgColor?: number;
  absWidth?: number;
  width?: number;
  height: number;
  borderColor?: ColorSource;
};

const viewMenuComponentMap: {
  [View in EdstView]?: ComponentType<ViewMenuProps<View>>;
} = {
  ALTIM_SET: ASViewMenu,
};

export const View = React.memo(
  <V extends EdstView>({
    view,
    children,
    absWidth,
    width: _width = 10,
    height,
    bgColor = 0,
    title = viewTitleMap[view]?.title ?? "TITLE MISSING",
    ...props
  }: ViewProps<V>) => {
    const ref = useRef<PixiContainer>(null);
    const dispatch = useRootDispatch();
    const { selected: showOptions } = useViewOptionSelected(view);
    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const viewOptions = useViewOptions(view);
    const pos = useRootSelector((state) => viewPositionSelector(state, view));
    const viewHeight = eramTextDimensionMap[2].height + 3 + height;

    const width = _width * eramTextDimensionMap[(viewOptions as { font?: EramFontSize })?.font ?? 2].width;
    const { x, y } = {
      x: Math.min(pos.x, windowWidth - width),
      y: Math.min(pos.y, windowHeight - viewHeight - 1),
    };
    const rectRef = useRef(new Rectangle(x, y, width, viewHeight));
    rectRef.current.x = x;
    rectRef.current.y = y;
    rectRef.current.width = width;
    rectRef.current.height = viewHeight;

    const { startDrag } = useDragging(rectRef, view);
    const zIndex = useZIndex(view, ref);

    useOnMount(() => {
      dispatch(pushZStack(view));
    });

    const handleOptionsMouseDown = () => {
      dispatch(setSelectedViewOption(view));
    };

    const ViewMenuComponent = viewMenuComponentMap[view] ?? ViewMenu;

    return (
      <>
        <Container ref={ref} x={x} y={y} zIndex={zIndex} sortableChildren>
          <ViewHeader
            width={width}
            title={title}
            handleOptionsMouseDown={handleOptionsMouseDown}
            onClose={() => dispatch(closeView(view))}
            startDrag={startDrag}
            opaque
            buttons={props.headerButtons}
          />
          {height > 0 && (
            <Container y={eramTextDimensionMap[2].height + 3} eventMode="static">
              <Graphics
                draw={(graphics) => {
                  graphics.clear();
                  graphics.lineStyle(1, baseBorderColor, 1);
                  graphics.beginFill(bgColor).drawRect(0, 0, width, height).endFill();
                }}
              />
              {children}
            </Container>
          )}
        </Container>
        {showOptions && (
          <Container x={x} y={y} zIndex={layerZIndexMap.viewMenu} sortableChildren>
            <ViewMenuComponent
              view={view}
              parentWidth={width}
              options={props.optionMap}
              viewMenuTitle={props.viewMenuTitle}
            />
          </Container>
        )}
      </>
    );
  },
);
