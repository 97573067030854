import React from "react";
import { giEntryMapSelector } from "~redux/slices/edstSlice";
import { useRootSelector } from "~redux/hooks";
import { viewOptionSelector } from "~redux/slices/viewOptionSlice";
import { View } from "components/utils/View";
import { useViewOptionSelected } from "@poscon/shared-frontend";

const view = "GI";

type GIRowProps = {
  text: string;
  selected: boolean;
  onDelete: () => void;
};
const GIRow = ({ text, selected, onDelete }: GIRowProps) => {
  const { selected: showOptions, openViewOption } = useViewOptionSelected(view);

  const onmousedown = () => {
    openViewOption();
  };

  return null;
};

export const GI = () => {
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const giEntryMap = useRootSelector(giEntryMapSelector);

  // TODO: implement
  return (
    // <ViewOptionContextProvider options={viewOptions}>
    <View width={120} height={0} title="GENERAL INFORMATION" view={view}>
      {null}
    </View>
    // </ViewOptionContextProvider>
  );
};
