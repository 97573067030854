export const layerZIndexMap = {
  buttons: 200,
  prompts: 198,
  map: 1,
  situationDisplay: 0,
  timeOverTracks: 199,
  timeUnderTracks: 99,
  toolbar: 101,
  tracks: 100,
  viewMenu: 190,
  viewsOverTracksBase: 102,
  viewsUnderTracksBase: 20,
};
