import React, { useSyncExternalStore } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { closeView, gpdAselSelector, setAsel } from "~redux/slices/edstSlice";
import { gpdSuppressedSelector, toggleGpdSuppressed, setGpdScaleToRange } from "~redux/slices/gpdSlice";
import { EdstButton } from "components/utils/EdstButton";
import { titleBarHeight, ViewTitleBar } from "components/ViewTitleBar";
import { openViewThunk } from "~redux/thunks/openViewThunk";
import type { HeaderComponentProps } from "components/utils/FullscreenView";
import { Container, Graphics } from "@pixi/react";
import type { DisplayObject, FederatedPointerEvent } from "pixi.js";
import { font2Dimension } from "~/utils/constants";
import { TBP } from "@poscon/shared-frontend";
import { gpdStore } from "~/gpdStore";

export const GpdHeader = ({ focused, toggleFullscreen, startDrag, width }: HeaderComponentProps) => {
  const asel = useRootSelector(gpdAselSelector);
  const gpdStoreValue = useSyncExternalStore(gpdStore.subscribe, gpdStore.getState);
  const suppressed = useRootSelector(gpdSuppressedSelector);
  const dispatch = useRootDispatch();

  const handleRangeClick = (event: FederatedPointerEvent) => {
    dispatch(
      setGpdScaleToRange(
        gpdStoreValue.range + (event.button === TBP ? -25 : 25),
        gpdStoreValue.range,
        gpdStoreValue.rect,
      ),
    );
  };

  const handleSuppressClick = () => {
    dispatch(toggleGpdSuppressed());
  };

  return (
    <Container>
      <ViewTitleBar
        width={width}
        focused={focused}
        toggleFullscreen={toggleFullscreen}
        startDrag={startDrag}
        closeView={() => {
          if (asel?.field?.endsWith("GPD_DB_FIELD")) {
            dispatch(setAsel(null));
          }
          dispatch(closeView("GPD"));
        }}
        text={["Graphic Plan Display - Current Time"]}
      />
      <Container y={titleBarHeight + 3}>
        <EdstButton
          x={4}
          disabled={asel === null}
          text="Plan Options..."
          onmousedown={(e) => {
            dispatch(openViewThunk("PLAN_OPTIONS", e.currentTarget as DisplayObject));
          }}
        />
        <EdstButton
          x={4 + font2Dimension.width * 15 + 8 * 2}
          disabled={asel === null}
          text="Hold..."
          onmousedown={(e) => {
            dispatch(openViewThunk("HOLD_MENU", e.target as DisplayObject));
          }}
        />
        <EdstButton x={4 + font2Dimension.width * 22 + 8 * 4} disabled text="Show" />
        <EdstButton x={4 + font2Dimension.width * 26 + 8 * 6} disabled text="Show ALL" />
        <EdstButton x={4 + font2Dimension.width * 34 + 8 * 8} disabled text="Graphic..." />
        <EdstButton
          x={4 + font2Dimension.width * 44 + 8 * 10}
          text="Template..."
          onmousedown={(e) => {
            dispatch(openViewThunk("TEMPLATE_MENU", e.currentTarget as DisplayObject));
          }}
        />
        <EdstButton x={4 + font2Dimension.width * 55 + 8 * 12} disabled text="Clean Up" />
      </Container>
      <Container y={titleBarHeight + 3 + font2Dimension.height + 8}>
        <Graphics
          draw={(graphics) => {
            graphics.clear();
            graphics
              .lineStyle(1, 0xadadad)
              .moveTo(0, font2Dimension.height + 8)
              .lineTo(width, font2Dimension.height + 8);
          }}
        />
        <EdstButton x={4} disabled text="Recenter" />
        <EdstButton
          x={4 + font2Dimension.width * 8 + 8 * 2}
          width={font2Dimension.width * 11 + 10}
          onmousedown={handleRangeClick}
          text={`Range ${gpdStoreValue.range}`}
        />
        <EdstButton
          x={4 + font2Dimension.width * 19 + 8 * 4}
          text={!suppressed ? "Suppress" : "Restore"}
          onmousedown={handleSuppressClick}
          width={font2Dimension.width * 8 + 10}
        />
        <EdstButton
          x={4 + font2Dimension.width * 27 + 8 * 6}
          text="Map Options..."
          onmousedown={(e) => {
            dispatch(openViewThunk("GPD_MAP_OPTIONS_MENU", e.currentTarget as DisplayObject));
          }}
        />
        <EdstButton
          x={4 + font2Dimension.width * 41 + 8 * 8}
          text="Tools..."
          onmousedown={(e) => {
            dispatch(openViewThunk("TOOLS_MENU", e.currentTarget as DisplayObject));
          }}
        />
        <EdstButton x={4 + font2Dimension.width * 49 + 8 * 10} disabled text="Saved Map" />
      </Container>
    </Container>
  );
  // return (
  //   <div className={tableStyles.header}>
  //     <WindowTitleBar
  //       focused={focused}
  //       toggleFullscreen={toggleFullscreen}
  //       startDrag={startDrag}
  //     />
  //     <div>
  //     </div>
  //     <div className={clsx(tableStyles.headerRow)}>
  //     </div>
  //   </div>
  // );
};
