import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import type { Container as PixiContainer } from "pixi.js";
import { setAddSaaAltId, viewPositionSelector } from "~redux/slices/edstSlice";
import { useDragging } from "hooks/useDragging";
import {
  colorNameMap,
  eramFontDimensionMap,
  eramFontNameMap,
  EramInput,
  EramPrompt,
  Line,
  MenuElement,
  saaSelector,
  selectedViewOptionSelector,
  setSelectedViewOption,
  toggleSelectedViewOption,
  useCenterCursor,
  useInputProps,
  ViewItemOptionContainer,
} from "@poscon/shared-frontend";
import { layerZIndexMap } from "~/layerZIndexMap";
import type { SaaId } from "@poscon/shared-types";
import { digits, includes } from "@poscon/shared-types";
import { BitmapText, Container } from "@pixi/react";
import { eramHubConnection } from "~/eramHubConnection";
import { createSaaActivationItemOptions } from "components/AirspaceStatus";

const view = "ADD_AIRSPACE_ALT_MENU";
const fontName = eramFontNameMap[2];
const { width: fontWidth, height: fontHeight } = eramFontDimensionMap[fontName];
const tint = colorNameMap.lightGrey;

const keyPredicate = (c: string) => includes(digits, c);

type AddAirspaceAltitudeProps = {
  saaId: SaaId;
};

export const addAspAltWidth = fontWidth * 23;
export const addAspAltBaseHeight = fontHeight * 8 + 5;

export const AddAirspaceAltitude = ({ saaId }: AddAirspaceAltitudeProps) => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiContainer>(null);
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const { startDrag } = useDragging(ref, view);
  const saa = useRootSelector((state) => saaSelector(state, saaId))!;
  const selectedViewOptionPath = useRootSelector(selectedViewOptionSelector);
  const lowerInputProps = useInputProps(
    `${view}/lowerAlt`,
    (saa.lowerAltitude / 100).toFixed(0).padStart(3, "0"),
    undefined,
    {
      maxLength: 3,
      resetOnMouseDown: true,
      keyPredicate,
    },
  );
  const upperInputProps = useInputProps(
    `${view}/upperAlt`,
    (saa.upperAltitude / 100).toFixed(0).padStart(3, "0"),
    undefined,
    {
      maxLength: 3,
      resetOnMouseDown: true,
      keyPredicate,
    },
  );

  useCenterCursor(ref);

  const rowsHeight = saa.activations.length ? saa.activations.length * (fontHeight + 3) + 1 : 0;
  const height = addAspAltBaseHeight + rowsHeight;

  return (
    <EramPrompt
      ref={ref}
      title="ADD AIRSPACE ALTITUDE"
      width={addAspAltWidth}
      height={height}
      closeMenu={() => dispatch(setAddSaaAltId(null))}
      pos={pos}
      startDrag={startDrag}
      showX
      zIndex={layerZIndexMap.prompts}
    >
      <BitmapText
        text={saa.saaName}
        x={Math.floor(addAspAltWidth / 2)}
        y={fontHeight}
        anchor={[0.5, 0]}
        style={{ tint, fontName }}
      />
      <EramInput
        {...lowerInputProps}
        x={fontWidth * 4}
        y={fontHeight * 3}
        width={fontWidth * 3 + 4}
        borderTint={colorNameMap.lightGrey}
      />
      <EramInput
        {...upperInputProps}
        x={addAspAltWidth - (fontWidth * 7 + 4)}
        y={fontHeight * 3}
        width={fontWidth * 3 + 4}
        borderTint={colorNameMap.lightGrey}
      />
      <BitmapText
        text="BOTTOM"
        x={Math.floor(fontWidth * 5.5 + 2)}
        y={fontHeight * 5}
        anchor={[0.5, 0]}
        style={{ fontName, tint }}
      />
      <BitmapText
        text="TOP"
        x={addAspAltWidth - Math.floor(fontWidth * 5.5 + 2)}
        y={fontHeight * 5}
        anchor={[0.5, 0]}
        style={{ fontName, tint }}
      />
      <MenuElement
        x={addAspAltWidth - fontWidth * 3 - 8}
        y={fontHeight * 7}
        text="ADD"
        onmousedown={() => {
          eramHubConnection.emit("addSaaActivation", saaId, {
            type: "PENDING",
            floor: parseInt(lowerInputProps.value, 10) * 100,
            ceiling: parseInt(upperInputProps.value, 10) * 100,
          });
        }}
      />
      <Line length={addAspAltWidth - 1} y={fontHeight * 8 + 4} tint={colorNameMap.mediumGrey} />
      <Container x={2} y={fontHeight * 8 + 6}>
        {saa.activations.map((activation, index) => {
          const state = activation.type === "PENDING" ? "REQ" : "ON";
          const lower = (activation.floor / 100).toFixed(0).padStart(3, "0");
          const upper = (activation.ceiling / 100).toFixed(0).padStart(3, "0");
          const text = `${state.padEnd(4, " ")} ${lower} - ${upper}`;
          const tint = activation.type === "PENDING" ? colorNameMap.yellow : colorNameMap.orange;
          const path = `${view}/addSaaAlt/${activation.id}`;
          const selected = selectedViewOptionPath === path;

          return (
            <Container key={activation.id} y={index * (fontHeight + 3)}>
              <MenuElement
                text={text}
                tint={tint}
                borderColor={selected ? tint : 0}
                fillColor={0}
                selected={selected}
                selectReverseVideo
                onmousedown={() => dispatch(toggleSelectedViewOption(path))}
              />
              {selected && (
                <ViewItemOptionContainer
                  baseX={0}
                  xOffset={fontWidth * 14 + 5}
                  options={createSaaActivationItemOptions(saa.saaId, activation, () =>
                    dispatch(setSelectedViewOption(null)),
                  )}
                />
              )}
            </Container>
          );
        })}
      </Container>
    </EramPrompt>
  );
};
