import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { aselSelector, closeView, viewPositionSelector } from "~redux/slices/edstSlice";
import { flightplanSelector } from "~redux/slices/aircraftSlice";
import { useDragging } from "hooks/useDragging";
import {
  baseBorderColor,
  eramFontNameMap,
  eramTextDimensionMap,
  processEramMessage,
  useCenterCursor,
  useInputProps,
} from "@poscon/shared-frontend";
import { useZIndex } from "hooks/useZIndex";
import type { Container as PixiContainer } from "pixi.js";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { MenuTitleBar } from "components/utils/MenuTitleBar";
import type { EramFlightplan } from "@poscon/shared-types";
import { formatCoordinationTime, formatRoute, formatSpeed, stringToParsedTokenArray } from "@poscon/shared-types";
import { defaultTint } from "~/utils/constants";
import { EdstButton } from "components/utils/EdstButton";
import { EdstInput } from "components/utils/EdstInput";
import { openViewThunk } from "~redux/thunks/openViewThunk";

const view = "TEMPLATE_MENU";

const fontName = eramFontNameMap[2];
const tint = defaultTint;
const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[2];

const width = 88 * fontWidth;
const height = 20 * fontHeight;

const aidX = fontWidth * 2;
const aidWidth = fontWidth * 8;
const numX = aidX + aidWidth + 8 * 2;
const numWidth = fontWidth * 3;
const saiX = numX + numWidth + 8 * 2;
const saiWidth = fontWidth * 3;
const typeX = saiX + saiWidth + 8 * 2;
const typeWidth = fontWidth * 5;
const equipX = typeX + typeWidth + 8 * 2;
const equipWidth = fontWidth * 6 + 2;
const bcnX = equipX + equipWidth + 8 * 2;
const bcnWidth = fontWidth * 5;
const spdX = bcnX + bcnWidth + 8 * 2;
const spdWidth = fontWidth * 5;
const fixX = spdX + spdWidth + 8 * 2;
const fixWidth = fontWidth * 14;
const timX = fixX + fixWidth + 8 * 2;
const timWidth = fontWidth * 8;
const altX = timX + timWidth + 8 * 2;
const altWidth = width - altX - 20;

const inputWidth = width - aidX - 20;

export const TemplateMenu = () => {
  const asel = useRootSelector(aselSelector);
  const fp = useRootSelector((state) => (asel ? flightplanSelector(state, asel.fpId) : null));
  return <TemplateMenuActual fp={fp} key={fp?.id} />;
};

type TemplateMenuProps = {
  fp: EramFlightplan | null;
};
const TemplateMenuActual = ({ fp }: TemplateMenuProps) => {
  const dispatch = useRootDispatch();
  const asel = useRootSelector(aselSelector);
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const ref = useRef<PixiContainer>(null);
  const zIndex = useZIndex(view, ref);
  useCenterCursor(ref);
  const { startDrag } = useDragging(ref, view, "mouseup");
  const formattedRoute = fp ? formatRoute(fp.route, fp.departure, fp.destination, fp.routeIsTruncated) : "";

  const aidInputProps = useInputProps(`${view}/aid`, fp?.callsign ?? "", undefined, {
    maxLength: 7,
    focusOnMount: false,
  });
  const numInputProps = useInputProps(`${view}/numOfAircraft`, fp?.numOfAircraft.toString() ?? "", undefined, {
    maxLength: 2,
    focusOnMount: false,
  });
  const saiInputProps = useInputProps(`${view}/sai`, "", undefined, {
    maxLength: 2,
    focusOnMount: false,
  });
  const typeInputProps = useInputProps(`${view}/aircraftType`, fp?.aircraftType ?? "", undefined, {
    maxLength: 4,
    focusOnMount: false,
  });
  const equipInputProps = useInputProps(`${view}/equipment`, fp?.equipmentQualifier ?? "", undefined, {
    maxLength: 1,
    focusOnMount: false,
  });
  const beaconInputProps = useInputProps(`${view}/beacon`, fp?.squawk ?? "", undefined, {
    maxLength: 4,
    focusOnMount: false,
  });
  const spdInputProps = useInputProps(
    `${view}/speed`,
    fp?.actualSpeed ? formatSpeed(fp.actualSpeed) : "",
    undefined,
    {
      maxLength: 4,
      focusOnMount: false,
    },
  );
  const fixInputProps = useInputProps(`${view}/fix`, fp?.coordinationFix ?? "", undefined, {
    maxLength: 11,
    focusOnMount: false,
  });
  const timInputProps = useInputProps(
    `${view}/time`,
    fp?.coordinationTime ? formatCoordinationTime(fp.coordinationTime) : "",
    undefined,
    { maxLength: 5, focusOnMount: false },
  );
  const altInputProps = useInputProps(`${view}/alt`, fp?.assignedAltitude?.simple?.toString() ?? "", undefined, {
    maxLength: 7,
    focusOnMount: false,
  });
  // const route = formattedRoute?.concat(fp?.destination ? appendDownArrowToString(fp.destination) : "");

  const inputChunkSize = Math.floor((inputWidth - 4) / fontWidth);
  const routeInputProps = useInputProps(`${view}/route`, formattedRoute ?? "", undefined, { focusOnMount: false });
  const rmkInputProps = useInputProps(`${view}/remarks`, "", undefined, {
    focusOnMount: false,
  });

  const send = () => {
    if (fp) {
      let msg = `AM ${fp?.cid ?? fp?.callsign}`;
      // TODO: add updated fields to msg
      dispatch(processEramMessage(stringToParsedTokenArray(msg)))
    }
  };

  return (
    <Container ref={ref} x={pos.x} y={pos.y} zIndex={zIndex} eventMode="static" sortableChildren>
      <MenuTitleBar
        title={`${asel ? "Amendment" : "Flight Plan"} Template`.toUpperCase()}
        width={width}
        onTitleMouseDown={startDrag}
        view="TEMPLATE_MENU"
      />
      <Container y={fontHeight + 2}>
        <Graphics
          draw={(graphics) => {
            graphics.clear();
            graphics.lineStyle(1, baseBorderColor, 1);
            graphics.beginFill(0).drawRect(0, 0, width, height).endFill();
          }}
        />
        <Container y={12}>
          <BitmapText text="AID" x={aidX + 2} style={{ fontName, tint }} />
          <BitmapText text="NUM" x={numX + 2} style={{ fontName, tint }} />
          <BitmapText text="SAI" x={saiX + 2} style={{ fontName, tint }} />
          <BitmapText text="TYPE" x={typeX + 2} style={{ fontName, tint }} />
          <EdstButton
            text="EQP..."
            x={equipX}
            y={-3}
            onmousedown={() => dispatch(openViewThunk("EQUIPMENT_TEMPLATE_MENU", ref.current))}
          />
          <BitmapText text="BCN" x={bcnX + 2} style={{ fontName, tint }} />
          <BitmapText text="SPD" x={spdX + 2} style={{ fontName, tint }} />
          <BitmapText text="FIX" x={fixX + 2} style={{ fontName, tint }} />
          <BitmapText text="TIM" x={timX + 2} style={{ fontName, tint }} />
          <BitmapText text="ALT" x={altX + 2} style={{ fontName, tint }} />
          <EdstButton text="MORE..." x={width - fontWidth * 8 - 10} y={-3} />
        </Container>
        <Container y={20 + fontHeight + 2}>
          <EdstInput x={aidX} width={aidWidth} {...aidInputProps} />
          <EdstInput x={numX} width={numWidth} {...numInputProps} />
          <EdstInput x={saiX} width={saiWidth} {...saiInputProps} />
          <EdstInput x={typeX} width={typeWidth} {...typeInputProps} />
          <EdstInput x={equipX} width={equipWidth} {...equipInputProps} />
          <EdstInput x={bcnX} width={bcnWidth} {...beaconInputProps} />
          <EdstInput x={spdX} width={spdWidth} {...spdInputProps} />
          <EdstInput x={fixX} width={fixWidth} {...fixInputProps} />
          <EdstInput x={timX} width={timWidth} {...timInputProps} />
          <EdstInput x={altX} width={altWidth} {...altInputProps} />
        </Container>
        <BitmapText text="RTE" x={aidX + 2} y={20 + fontHeight * 3} style={{ fontName, tint }} />
        <EdstInput
          x={aidX}
          y={28 + fontHeight * 4}
          width={inputWidth}
          height={fontHeight * 4}
          chunkSize={inputChunkSize}
          {...routeInputProps}
        />
        <BitmapText text="RMK" x={aidX + 2} y={44 + fontHeight * 8} style={{ fontName, tint }} />
        <EdstInput
          x={aidX}
          y={52 + fontHeight * 9}
          width={inputWidth}
          height={fontHeight * 3}
          chunkSize={inputChunkSize}
          {...rmkInputProps}
        />
      </Container>
      <EdstButton text="SEND" onmousedown={send} x={4} y={height - fontHeight - 7} />
      <EdstButton text="CANCEL" onmousedown={() => {
        dispatch(closeView(view));
      }} x={width - fontWidth * 7 - 10} y={height - fontHeight - 7} />
    </Container>
  );
};
