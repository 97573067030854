import React from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import {
  aclAselSelector,
  closeView,
  setAsel,
  setViewPosition,
} from "~redux/slices/edstSlice";
import {
  stringToParsedTokenArray,
  isAclRowField,
  AclSortMethodValues,
} from "@poscon/shared-types";
import {
  aclPostingModeSelector,
  aclSortDataSelector,
} from "~redux/slices/aclSlice";
import type { HeaderComponentProps } from "components/utils/FullscreenView";
import { EdstButton } from "components/utils/EdstButton";
import { titleBarHeight, ViewTitleBar } from "components/ViewTitleBar";
import { openViewThunk } from "~redux/thunks/openViewThunk";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import type { DisplayObject } from "pixi.js";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { defaultTint, font2, font2Dimension } from "~/utils/constants";
import { aircraftSelect } from "~redux/thunks/aircraftSelect";
import { eramHubConnection } from "~/eramHubConnection";
import { useInputProps } from "@poscon/shared-frontend";
import { EdstInput } from "components/utils/EdstInput";

export const aclHeaderHeight = titleBarHeight + font2Dimension.height * 3 + 11;

/**
 * ACL title bar and header row with add/find input field
 */
export const AclHeader = ({
  focused,
  toggleFullscreen,
  startDrag,
  width,
}: HeaderComponentProps) => {
  const asel = useRootSelector(aclAselSelector);
  const sortData = useRootSelector(aclSortDataSelector);
  const postingMode = useRootSelector(aclPostingModeSelector);
  const dispatch = useRootDispatch();
  const addFindInputProps = useInputProps(
    `ACL/header/addFind`,
    "",
    (searchStr) => {
      dispatch(processEramMessage(stringToParsedTokenArray(`UU ${searchStr}`)));
    },
    {
      maxLength: 7,
      focusOnMount: false,
    },
  );

  return (
    <Container>
      <ViewTitleBar
        width={width}
        focused={focused}
        toggleFullscreen={toggleFullscreen}
        startDrag={startDrag}
        closeView={() => {
          if (isAclRowField(asel?.field)) {
            setAsel(null);
          }
          dispatch(closeView("ACL"));
        }}
        text={[
          "Aircraft List",
          `${sortData.sector ? "Sector/" : ""}${AclSortMethodValues[sortData.selectedOption]}`,
          `${postingMode === "MANUAL" ? "Manual" : "Automatic"}`,
        ]}
      />
      <Container y={titleBarHeight + 3}>
        <Graphics
          draw={(graphics) => {
            graphics.clear();
            graphics
              .lineStyle(1, 0xadadad)
              .moveTo(1, font2Dimension.height + 8)
              .lineTo(width, font2Dimension.height + 8)
              .moveTo(1, aclHeaderHeight - (titleBarHeight + 3))
              .lineTo(width, aclHeaderHeight - (titleBarHeight + 3));
          }}
        />
        <EdstButton
          x={4}
          disabled={asel === null}
          text="Plan Options..."
          onmousedown={(e) => {
            if (asel) {
              const target = e.target as DisplayObject;
              const bounds = target.getBounds();
              const newViewPosition = {
                x: bounds.x,
                y: bounds.y + bounds.height,
              };
              dispatch(
                setViewPosition({ view: "PLAN_OPTIONS", pos: newViewPosition }),
              );
              dispatch(aircraftSelect(asel.fpId, asel.field, "PLAN_OPTIONS"));
            }
          }}
        />
        <EdstButton
          x={4 + font2Dimension.width * 15 + 8 * 2}
          disabled={asel === null}
          text="Hold..."
          onmousedown={(e) => {
            if (asel) {
              const target = e.target as DisplayObject;
              const bounds = target.getBounds();
              const newViewPosition = {
                x: bounds.x,
                y: bounds.y + bounds.height,
              };
              dispatch(
                setViewPosition({ view: "HOLD_MENU", pos: newViewPosition }),
              );
              dispatch(aircraftSelect(asel.fpId, asel.field, "HOLD_MENU"));
            }
          }}
        />
        <EdstButton
          x={4 + font2Dimension.width * 22 + 8 * 4}
          disabled
          text="Show"
        />
        <EdstButton
          x={4 + font2Dimension.width * 26 + 8 * 6}
          disabled
          text="Show ALL"
        />
        <EdstButton
          x={4 + font2Dimension.width * 34 + 8 * 8}
          text="Sort..."
          onmousedown={(e) => {
            dispatch(openViewThunk("ACL_SORT_MENU", e.target as DisplayObject));
          }}
        />
        <EdstButton
          x={4 + font2Dimension.width * 41 + 8 * 10}
          text="Tools..."
          onmousedown={(e) => {
            dispatch(openViewThunk("TOOLS_MENU", e.target as DisplayObject));
          }}
        />
        <EdstButton
          x={4 + font2Dimension.width * 49 + 8 * 12}
          onmousedown={() => eramHubConnection.emit("toggleAclPostingMode")}
          text="Posting Mode"
        />
        <EdstButton
          x={4 + font2Dimension.width * 61 + 8 * 14}
          text="Template..."
          onmousedown={(e) => {
            dispatch(openViewThunk("TEMPLATE_MENU", e.target as DisplayObject));
          }}
        />
        <EdstButton
          x={4 + font2Dimension.width * 72 + 8 * 16}
          onmousedown={() =>
            dispatch(processEramMessage(stringToParsedTokenArray("UU C")))
          }
          text="Clean Up"
        />
      </Container>
      <Container y={aclHeaderHeight - (font2Dimension.height + 13)}>
        <BitmapText
          text="ADD/FIND"
          y={4}
          x={font2Dimension.width}
          style={{ tint: defaultTint, fontName: font2 }}
        />
        <EdstInput
          {...addFindInputProps}
          x={font2Dimension.width * 11}
          width={font2Dimension.width * 9}
        />
      </Container>
    </Container>
  );
};
