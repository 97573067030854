import React, { useRef, useState } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { viewSelector } from "~redux/slices/edstSlice";
import { useDragging } from "hooks/useDragging";
import { eramFontNameMap, eramTextDimensionMap, useCenterCursor, useFocused } from "@poscon/shared-frontend";
import type { Container as PixiContainer } from "pixi.js";
import { useZIndex } from "hooks/useZIndex";
import { Container, Graphics } from "@pixi/react";
import { MenuTitleBar } from "components/utils/MenuTitleBar";
import { TableField } from "components/utils/TableField";
import { openViewThunk } from "~redux/thunks/openViewThunk";

const fontName = eramFontNameMap[2];
const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[2];

const width = 36 * fontWidth;

export const ToolsMenu = () => {
  const dispatch = useRootDispatch();
  const viewProps = useRootSelector((state) => viewSelector(state, "TOOLS_MENU"));
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const ref = useRef<PixiContainer>(null);
  const zIndex = useZIndex("TOOLS_MENU", ref);
  useCenterCursor(ref);
  const { startDrag } = useDragging(ref, "TOOLS_MENU", "mouseup");

  return (
    <Container
      ref={ref}
      x={viewProps.position.x}
      y={viewProps.position.y}
      zIndex={zIndex}
      eventMode="static"
      sortableChildren
    >
      <MenuTitleBar title="TOOLS MENU" width={width} onTitleMouseDown={startDrag} view="TOOLS_MENU" />
      <Container y={fontHeight + 2} sortableChildren>
        <Graphics
          draw={(graphics) => {
            graphics.clear();
            graphics.lineStyle(1, 0xadadad);
            graphics
              .beginFill(0x000000)
              .drawRect(0, 0, width, (fontHeight + 4) * 4 + 6)
              .endFill();
          }}
        />
        <TableField
          textX={2}
          x={4}
          y={4}
          text="Airspace Status..."
          width={width - 8}
          onmousedown={() => dispatch(openViewThunk("AIRSPACE_STATUS"))}
        />
        <TableField
          textX={2}
          x={4}
          y={4 + fontHeight + 4}
          text="Airport Stream Filter Status..."
          width={width - 8}
        />
        <TableField textX={2} x={4} y={4 + (fontHeight + 4) * 2} text="Options..." width={width - 8} />
        <TableField textX={2} x={4} y={4 + (fontHeight + 4) * 3} text="Restrictions..." width={width - 8} />
      </Container>
    </Container>
  );
};

const ToolsOptionsMenu = () => {
  // TODO: implement
  return null;
};
