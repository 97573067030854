import React from "react";
import { View } from "components/utils/View";
import { brightOption, fontOption } from "~redux/slices/viewOptionSlice";

const view = "OUTAGE";

export const initialOutageOptions = {
  background: true,
  border: true,
  font: 2,
  bright: 80,
};

const optionMap = {
  font: fontOption(view),
  bright: brightOption(view),
};

export const Outage = () => {
  return <View width={20} height={0} view={view} />;
};
