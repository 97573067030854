import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "~redux/store";
import type { EdstFpData, FlightplanId } from "@poscon/shared-types";

export type EdstFlightplanSlice = {
  aclAckList: FlightplanId[];
  aclUnackList: FlightplanId[];
  aclSpaList: FlightplanId[];
  depAckList: FlightplanId[];
  depUnackList: FlightplanId[];
  depSpaList: FlightplanId[];
  edstFlightplans: Record<FlightplanId, EdstFpData>;
};

const initialState: EdstFlightplanSlice = {
  aclAckList: [],
  aclUnackList: [],
  aclSpaList: [],
  depAckList: [],
  depUnackList: [],
  depSpaList: [],
  edstFlightplans: {},
};

const edstDataSlice = createSlice({
  name: "edstFlightplans",
  initialState,
  reducers: {
    setAclAckList(state, action: PayloadAction<FlightplanId[]>) {
      state.aclAckList = action.payload;
    },
    setAclUnackList(state, action: PayloadAction<FlightplanId[]>) {
      state.aclUnackList = action.payload;
    },
    setAclSpaList(state, action: PayloadAction<FlightplanId[]>) {
      state.aclSpaList = action.payload;
    },
    setDepAckList(state, action: PayloadAction<FlightplanId[]>) {
      state.depAckList = action.payload;
    },
    setDepUnackList(state, action: PayloadAction<FlightplanId[]>) {
      state.depUnackList = action.payload;
    },
    setDepSpaList(state, action: PayloadAction<FlightplanId[]>) {
      state.depSpaList = action.payload;
    },
    updateEdstFlightplan(
      state,
      action: PayloadAction<{
        fpId: FlightplanId;
        data: EdstFpData;
      }>,
    ) {
      state.edstFlightplans[action.payload.fpId] = action.payload.data;
    },
    deleteEdstFp(state, action: PayloadAction<FlightplanId>) {
      delete state.edstFlightplans[action.payload];
    },
    setEdstFlightplans(state, action: PayloadAction<Record<FlightplanId, EdstFpData>>) {
      state.edstFlightplans = action.payload;
    },
  },
});

export const {
  setAclAckList,
  setAclUnackList,
  setDepAckList,
  setDepUnackList,
  setDepSpaList,
  setAclSpaList,
  setEdstFlightplans,
  deleteEdstFp,
  updateEdstFlightplan,
} = edstDataSlice.actions;
export const reducer = edstDataSlice.reducer;

export const aclAckListSelector = (state: RootState) => state.edstData.aclAckList;
export const aclUnackListSelector = (state: RootState) => state.edstData.aclUnackList;
export const aclSpaListSelector = (state: RootState) => state.edstData.aclSpaList;
export const depAckListSelector = (state: RootState) => state.edstData.depAckList;
export const depUnackListSelector = (state: RootState) => state.edstData.depUnackList;
export const depSpaListSelector = (state: RootState) => state.edstData.depSpaList;
export const edstFlightplansSelector = (state: RootState) => state.edstData.edstFlightplans;
export const edstFpSelector = createSelector(
  [(state: RootState) => state.edstData.edstFlightplans, (state: RootState, fpId: string) => fpId],
  (edstFlightplans, fpId) => edstFlightplans[fpId] ?? {},
);
