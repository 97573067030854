import type { Action, ThunkAction, Reducer } from "@reduxjs/toolkit";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { reducer as aclReducer } from "~redux/slices/aclSlice";
import { reducer as depReducer } from "~redux/slices/depSlice";
import { reducer as gpdReducer } from "~redux/slices/gpdSlice";
import { reducer as trialPlanReducer } from "~redux/slices/trailPlanSlice";
import { reducer as edstReducer } from "~redux/slices/edstSlice";
import { viewOptionSlice, reducer as viewOptionsReducer } from "~redux/slices/viewOptionSlice";
import { reducer as edstFlightplanReducer } from "~/redux/slices/edstFlightplanSlice";
import {
  aircraftReducer,
  navdataApi,
  weatherReducer,
  connectionReducer,
  commonReducer,
  messagingReducer,
  connectionSelector,
  isRestrictedUiAction,
  viewMenuFieldsReducer,
  messagingSlice,
  commonSlice,
  viewMenuFieldsSlice,
  connectionSlice,
  createRootReducer,
} from "@poscon/shared-frontend";
import { listenerMiddleware } from "~redux/listenerMiddleware";
import { injectStore } from "~/injectStore";
import { uiActionReducerNames } from "~/eramHubConnection";
import { whitelistedActions } from "~redux/slices/whitelistedActions";
import { EramConnection } from "@poscon/shared-types";

const combinedReducer = combineReducers({
  edst: edstReducer,
  acl: aclReducer,
  dep: depReducer,
  gpd: gpdReducer,
  trialPlan: trialPlanReducer,
  [viewOptionSlice.name]: viewOptionsReducer,
  tracks: aircraftReducer,
  edstData: edstFlightplanReducer,
  [messagingSlice.name]: messagingReducer,
  [connectionSlice.name]: connectionReducer,
  weather: weatherReducer,
  [commonSlice.name]: commonReducer,
  [viewMenuFieldsSlice.name]: viewMenuFieldsReducer,
  [navdataApi.reducerPath]: navdataApi.reducer,
});

const rootReducer: Reducer<ReturnType<typeof combinedReducer>> = createRootReducer((state, action) => {
  const connection = state ? connectionSelector(state) as EramConnection : undefined;
  if (
    isRestrictedUiAction(action, uiActionReducerNames, [], whitelistedActions) &&
    !action.meta?.forwarded &&
    connection &&
    connection.sectorId &&
    (connection.hasActiveRadarConnection || connection.hasActiveDataConnection) &&
    !connection.isActive &&
    state
  ) {
    return state;
  }
  return combinedReducer(state, action);
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      listenerMiddleware.middleware,
      navdataApi.middleware,
    ]),
});

injectStore(store);

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
export type RootThunkAction<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type RootStore = typeof store;
