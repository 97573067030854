import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { View } from "components/utils/View";
import {
  brightOption,
  buttonOption,
  counterOption,
  fontOption,
  linesOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import {
  createItemOption,
  ViewItemOptionContainer,
  colorNameMap,
  computeColor,
  eramFontDimensionMap,
  eramFontNameMap,
  useAltimeter,
  useCurrentTime,
  useFocused,
  useViewOptionSelected, altimeterAirportsSelector,
} from "@poscon/shared-frontend";
import type { EramFontSize } from "@poscon/shared-frontend";

import { processEramMessage } from "~redux/thunks/processEramMessage";
import { formatUtcTime, stringToParsedTokenArray } from "@poscon/shared-types";
import type { Container as PixiContainer } from "pixi.js";
import { Rectangle } from "pixi.js";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { useViewOptions, ViewOptionContextProvider } from "contexts/viewOptionContext";

const view = "ALTIM_SET";

const optionMap = {
  lines: linesOption(view, 2, 21),
  column: counterOption(view, "column", "COLUMN", 1, 10, 9),
  font: fontOption(view),
  bright: brightOption(view),
  template: buttonOption(view, "TEMPLATE", 8),
};

type AltimeterRowProps = {
  stationCode: string;
};
export const AltimeterRow = ({ stationCode }: AltimeterRowProps) => {
  const dispatch = useRootDispatch();
  const { selected, toggleViewOption } = useViewOptionSelected(`${view}/${stationCode}`);
  const ref = useRef<PixiContainer>(null);
  const focused = useFocused(ref);
  const viewOptions = useViewOptions(view);
  const itemOptions = [
    createItemOption(`DELETE ${stationCode}`, () => {
      dispatch(processEramMessage(stringToParsedTokenArray(`QD ${stationCode}`)));
    }),
  ];

  const fontSize = viewOptions.font as EramFontSize;
  const fontName = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontName];

  const { timeRecorded, altimeter } = useAltimeter(stationCode);

  const now = useCurrentTime();
  const observationThreshold1 = new Date(timeRecorded + 3900e3);
  const observationThreshold2 = new Date(timeRecorded + 7200e3);

  const width = 17 * fontDimension.width + 4;
  const height = fontDimension.height + 2;

  const alpha = (viewOptions.bright * 0.8) / 100 + 0.2;

  const tint = selected ? 0 : computeColor(colorNameMap.white, viewOptions.bright / 100);
  const style = { fontName, tint };

  const bgColor = computeColor(selected ? colorNameMap.grey : colorNameMap.black, selected ? alpha : 1);

  return (
    <Container>
      <Container eventMode="static" ref={ref} sortableChildren>
        <Graphics
          hitArea={new Rectangle(0, 0, width, height)}
          eventMode="static"
          zIndex={1}
          draw={(graphics) => {
            graphics.clear();

            graphics.lineStyle(1, focused ? 0xffffff : selected ? bgColor : 0x000000);
            graphics
              .beginFill(bgColor, selected ? 1 : 0)
              .drawRect(0, 0, width, height)
              .endFill();

            graphics.lineStyle(1, tint);
            if (observationThreshold1 < now) {
              graphics
                .moveTo(fontDimension.width * 7, fontDimension.height)
                .lineTo(fontDimension.width * 11, fontDimension.height);
            }
            if (altimeter && parseInt(altimeter, 10) < 2992) {
              graphics
                .moveTo(fontDimension.width * 13, fontDimension.height)
                .lineTo(fontDimension.width * 16, fontDimension.height);
            }
          }}
          onmousedown={() => {
            toggleViewOption();
          }}
        />
        <BitmapText
          x={fontDimension.width * 2}
          y={2}
          zIndex={3}
          text={stationCode}
          eventMode="none"
          fontName={fontName}
          tint={tint}
          style={style}
        />
        <BitmapText
          x={fontDimension.width * 7}
          y={2}
          zIndex={3}
          text={formatUtcTime(timeRecorded)}
          eventMode="none"
          fontName={fontName}
          tint={tint}
          style={style}
        />
        <BitmapText
          x={fontDimension.width * 13}
          y={2}
          zIndex={3}
          text={!altimeter || observationThreshold2 < now ? "-M-" : altimeter.slice(1)}
          eventMode="none"
          fontName={fontName}
          tint={tint}
          style={style}
        />
      </Container>
      {selected && <ViewItemOptionContainer xOffset={width} options={itemOptions} />}
    </Container>
  );
};

export const AltimSet = () => {
  const airports = useRootSelector(altimeterAirportsSelector);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const fontDimension = eramFontDimensionMap[eramFontNameMap[viewOptions.font as EramFontSize]];

  const height = airports.length > 0 ? (fontDimension.height + 4) * airports.length + 8 : 0;

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View width={20} view="ALTIM_SET" optionMap={optionMap} height={height}>
        {airports.length > 0 && (
          <Container x={2} y={4} eventMode="static">
            {airports.map(({ station }, index) => (
              <Container
                key={`${station}-${index}`}
                eventMode="static"
                x={8}
                y={(fontDimension.height + 6) * index}
              >
                <AltimeterRow stationCode={station} />
              </Container>
            ))}
          </Container>
        )}
      </View>
    </ViewOptionContextProvider>
  );
};
