import React, { useEffect, useState } from "react";
import { BitmapText } from "@pixi/react";
import { eramFontDimensionMap, eramFontNameMap } from "@poscon/shared-frontend";

const fontName = eramFontNameMap[6];
const fontDimension = eramFontDimensionMap[fontName];

export const timeWidth = fontDimension.width * 7;

export function Time({ x }: { x: number }) {
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <BitmapText
      x={x}
      y={6}
      text={`${date.getUTCHours().toString().padStart(2, "0")}${date
        .getUTCMinutes()
        .toString()
        .padStart(2, "0")} ${date.getUTCSeconds().toString().padStart(2, "0")}`}
      style={{ fontName, tint: 0xadadad }}
    />
  );
}
