import type { PosconRole, ArtccId, InflatedEdstClientEventMap, ControllerSectorId } from "@poscon/shared-types";
import type { RootState, RootStore } from "~redux/store";
import { edstSlice, setDPosConfig, setEdstState } from "~redux/slices/edstSlice";
import type { EramHubConnection } from "@poscon/shared-frontend";
import { viewMenuFieldsSlice, viewOptionSliceName, setAltimeterList, setMetarList } from "@poscon/shared-frontend";
import { isRestrictedUiAction, uiSubscribedSelector, messagingSlice, baseEramHubConnection } from "@poscon/shared-frontend";
import {
  deleteEdstFp,
  setAclAckList,
  setAclSpaList,
  setAclUnackList,
  setDepAckList,
  setDepSpaList,
  setDepUnackList,
  setEdstFlightplans,
  updateEdstFlightplan,
} from "~redux/slices/edstFlightplanSlice";
import { aclSlice, setAclPostingMode, setAclSort, setAclState } from "~redux/slices/aclSlice";
import { depSlice, setDepPostingMode, setDepSort, setDepState } from "~redux/slices/depSlice";
import { env } from "~/env";
import { setTrialPlanQueue } from "~redux/slices/trailPlanSlice";
import type { DefaultEventsMap } from "@socket.io/component-emitter";
import { setViewOptionState } from "~redux/slices/viewOptionSlice";
import { listenerMiddleware } from "~redux/listenerMiddleware";
import { gpdSlice, setGpdState } from "~redux/slices/gpdSlice";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";

const syncedUiSlices = [edstSlice.name, viewOptionSliceName, aclSlice.name, depSlice.name, gpdSlice.name] as const;
type SyncedUiSlice = (typeof syncedUiSlices)[number];

let store: RootStore;
export const ERAM_SERVER_URL = env.VITE_ERAM_SERVER_URL;
export const eramHubConnection = baseEramHubConnection as EramHubConnection<
  InflatedEdstClientEventMap & DefaultEventsMap
>;
eramHubConnection.url = ERAM_SERVER_URL;

export const injectStore = async (s: RootStore) => {
  store = s;
  eramHubConnection.dispatch = store.dispatch;
  eramHubConnection.getState = () => {
    const state = store.getState();
    return Object.fromEntries(syncedUiSlices.map((slice) => [slice, state[slice]]));
  };
  eramHubConnection.registerEventHandler("receiveUiState", (_state) => {
    const state = _state as Pick<RootState, SyncedUiSlice>;
    store.dispatch(setViewOptionState(state.viewOptions));
    store.dispatch(setEdstState(state.edst));
    store.dispatch(setAclState(state.acl));
    store.dispatch(setDepState(state.dep));
    store.dispatch(setGpdState(state.gpd));
  });
  eramHubConnection.registerEventHandler("receiveAltimeterList", (altimeterList) => {
    store.dispatch(setAltimeterList(altimeterList));
  });
  eramHubConnection.registerEventHandler("receiveMetarList", (metarList) => {
    store.dispatch(setMetarList(metarList));
  });
  eramHubConnection.registerEventHandler("receiveAclPostingMode", (postingMode) => {
    store.dispatch(setAclPostingMode(postingMode));
  });
  eramHubConnection.registerEventHandler("receiveDepPostingMode", (postingMode) => {
    store.dispatch(setDepPostingMode(postingMode));
  });
  eramHubConnection.registerEventHandler("receiveAclSortMethod", (sortMethod, sortSector) => {
    store.dispatch(setAclSort({ selectedOption: sortMethod, sector: sortSector }));
  });
  eramHubConnection.registerEventHandler("receiveDepSortMethod", (sortMethod) => {
    store.dispatch(setDepSort(sortMethod));
  });
  eramHubConnection.registerEventHandler("receiveAclUnackList", (aclUnackList) => {
    store.dispatch(setAclUnackList(aclUnackList));
  });
  eramHubConnection.registerEventHandler("receiveAclAckList", (aclAckList) => {
    store.dispatch(setAclAckList(aclAckList));
  });
  eramHubConnection.registerEventHandler("receiveAclSpaList", (aclSpaList) => {
    store.dispatch(setAclSpaList(aclSpaList));
  });
  eramHubConnection.registerEventHandler("receiveDepUnackList", (depUnackList) => {
    store.dispatch(setDepUnackList(depUnackList));
  });
  eramHubConnection.registerEventHandler("receiveDepAckList", (depAckList) => {
    store.dispatch(setDepAckList(depAckList));
  });
  eramHubConnection.registerEventHandler("receiveDepSpaList", (depSpaList) => {
    store.dispatch(setDepSpaList(depSpaList));
  });
  eramHubConnection.registerEventHandler("receiveTrialPlans", (trialPlans) => {
    store.dispatch(setTrialPlanQueue(trialPlans));
  });
  eramHubConnection.registerEventHandler("receiveEdstFpMap", (fpMap) => {
    store.dispatch(setEdstFlightplans(fpMap));
  });
  eramHubConnection.registerEventHandler("updateEdstFp", (fpId, data) => {
    store.dispatch(updateEdstFlightplan({ fpId, data }));
  });
  eramHubConnection.registerEventHandler("deleteEdstFp", (fpId) => {
    store.dispatch(deleteEdstFp(fpId));
  });
};

export async function initializeConnection(
  artccId: ArtccId,
  sectorId: ControllerSectorId | null = null,
  role: PosconRole = "Data",
) {
  await eramHubConnection.initialize(artccId, "EDST", ERAM_SERVER_URL, sectorId, role);
  eramHubConnection.getDposConfig().then((dPosConfig) => {
    store.dispatch(setDPosConfig(dPosConfig));
  });
}

export const uiActionReducerNames: string[] = [
  viewOptionSliceName,
  edstSlice.name,
  messagingSlice.name,
  aclSlice.name,
  depSlice.name,
  gpdSlice.name,
  viewMenuFieldsSlice.name,
];

listenerMiddleware.startListening({
  predicate: (action, currentState) =>
    uiSubscribedSelector(currentState as any) && isRestrictedUiAction(action, uiActionReducerNames),
  effect: (action) => {
    eramHubConnection.emit("uiAction", action);
  },
});

if (window.__TAURI__) {
  const webviewWindow = WebviewWindow.getCurrent();
  await webviewWindow.listen("poscon:close", async () => {
    if (eramHubConnection.isActive) {
      try {
        await eramHubConnection.signout();
      } catch (err) {
        console.error(err);
      }
    }
    void webviewWindow.close();
  });
}
