import type { ReactNode } from "react";
import React, { createContext } from "react";

type TableColumnPositionContextValue<T extends string = string> = {
  positions: Record<T, number>;
  widths: Record<T, number>;
  hiddenColumns: T[];
};

const TableColumnPositionContext = createContext<TableColumnPositionContextValue | null>(null);

export const TableColumnPositionProvider = <T extends string>({
  children,
  value,
}: {
  children: ReactNode;
  value: TableColumnPositionContextValue<T>;
}) => {
  return <TableColumnPositionContext.Provider value={value}>{children}</TableColumnPositionContext.Provider>;
};

export const useTableColumnPositions = <T extends string>() => {
  const value = React.useContext(TableColumnPositionContext);
  if (value === null) {
    throw new Error(`useTableColumnPositions must be used within a TableColumnPositionProvider`);
  }
  return value as TableColumnPositionContextValue<T>;
};
