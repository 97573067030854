import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { aircraftIsAselSelector, showSatCommIndicatorSelector } from "~redux/slices/edstSlice";
import { aclPostingModeSelector, toolsOptionsSelector } from "~redux/slices/aclSlice";
import type { EdstAselMenu } from "types/edstView";
import type {
  AclRowField,
  EdstFpData,
  EramCommunicationStatus,
  FlightplanId,
  SectorId,
  EramSectorTrack,
  TrackId,
} from "@poscon/shared-types";
import { openMenuThunk } from "~redux/thunks/openMenuThunk";
import {
  formatRoute,
  stringToParsedTokenArray,
  TOC_COMPLETE_SYMBOL,
  VCI_SYMBOL,
  EramFlightplan,
  CDA_ELIG_SYMBOL,
  CDA_NOT_ELIG_SYMBOL,
} from "@poscon/shared-types";
import {
  cpdlcSessionSelector,
  flightplanSelector,
  sectorTrackSelector,
  trackCoordinationDataSelector,
  trackFromFpIdSelector,
} from "~redux/slices/aircraftSlice";
import { eramHubConnection } from "~/eramHubConnection";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import type { ColorSource, DisplayObject, FederatedEventHandler, Graphics as PixiGraphics } from "pixi.js";
import { aircraftSelect } from "~redux/thunks/aircraftSelect";
import {
  alertOrange,
  alertRed,
  alertYellow,
  cpdlcFont2,
  cpdlcFont2Dimension,
  defaultTint,
  font2,
  font2Dimension,
  REMOVAL_TIMEOUT,
} from "~/utils/constants";
import { edstFpSelector } from "~redux/slices/edstFlightplanSlice";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { TableField } from "components/utils/TableField";
import {
  eramCpdlcFontNameMap,
  EramInput,
  sectorIdSelector,
  useFocused,
  useInputProps,
  useStableCallback,
} from "@poscon/shared-frontend";
import { useTableColumnPositions } from "contexts/TableColumnPositionContext";
import type { EramCpdlcSession } from "@poscon/eram-cpdlc";
import { Rectangle } from "pixi.js";

type AdditionalFields =
  | "VCI"
  | "ALERT_RED"
  | "ALERT_ORANGE"
  | "ALERT_YELLOW"
  | "REMARKS_TOGGLE_BOX"
  | "HOLD_INDICATOR";

type AclRowProps = {
  fpId: FlightplanId;
};

const vciTextMap: Record<NonNullable<EramCommunicationStatus>, string> = {
  ON_FREQ: VCI_SYMBOL,
  TOC: TOC_COMPLETE_SYMBOL,
};

export const AclRow = React.memo(({ fpId }: AclRowProps) => {
  const fp = useRootSelector((state) => flightplanSelector(state, fpId));
  const edstFp = useRootSelector((state) => edstFpSelector(state, fpId));
  const track = useRootSelector((state) => trackFromFpIdSelector(state, fpId));
  const sectorTrack = useRootSelector((state) => (track ? sectorTrackSelector(state, track.id) : null));
  const cpdlcSession = useRootSelector((state) => cpdlcSessionSelector(state, fpId));

  return fp && edstFp ? (
    <AclRowActual
      trackId={track?.id}
      owner={track?.owner}
      ownerShort={track?.ownerShort}
      sectorTrack={sectorTrack}
      fp={fp}
      edstFp={edstFp}
      cpdlcSession={cpdlcSession}
    />
  ) : null;
});

type FlidColProps = {
  x?: number;
  y?: number;
  width: number;
  height: number;
  tint: ColorSource;
  selected?: boolean;
  owner?: SectorId | null;
  ownerShort?: string | null;
  fp: EramFlightplan;
  edstFp: EdstFpData;
  cpdlcSession?: EramCpdlcSession;
  onmousedown: FederatedEventHandler;
};
const FlidCol = ({
  x = 0,
  y = 0,
  width,
  height,
  onmousedown,
  tint,
  selected,
  owner,
  ownerShort,
  fp,
  edstFp,
  cpdlcSession,
}: FlidColProps) => {
  const sectorId = useRootSelector(sectorIdSelector);
  const ref = useRef<PixiGraphics>(null);
  const focused = useFocused(ref);
  const showSatCommIndicator = useRootSelector(showSatCommIndicatorSelector);

  const callsignPrefix = cpdlcSession && sectorId ? (cpdlcSession.eligibleSectorId === sectorId
    ? CDA_ELIG_SYMBOL
    : CDA_NOT_ELIG_SYMBOL) : null;

  return (
    <Container x={x} y={y} sortableChildren eventMode="static">
      <Graphics
        ref={ref}
        eventMode="static"
        onmousedown={onmousedown}
        hitArea={new Rectangle(0, 0, width, height)}
        draw={(graphics) => {
          graphics.clear();
          if (focused) {
            graphics.lineStyle(1, 0xf0f0f0);
          }
          graphics
            .beginFill(selected ? tint : 0, selected ? 1 : 0)
            .drawRect(0, 0, width, height)
            .endFill();
          if (selected) {
            const fillColor = selected ? tint : 0x000000;
            graphics.beginFill(fillColor).drawRect(0, 0, width, height).endFill();
            if (callsignPrefix) {
              graphics.lineStyle();
              graphics
                .beginFill(edstFp.highlight ? 0x414141 : 0x000000)
                .drawRect(
                  font2Dimension.width * 3 + cpdlcFont2Dimension.width * (2 - callsignPrefix.length),
                  0,
                  cpdlcFont2Dimension.width * callsignPrefix.length,
                  height - (focused ? 1 : 0),
                )
                .endFill();
            }
          }
        }}
      />
      {callsignPrefix && (
        <BitmapText
          eventMode="none"
          x={font2Dimension.width * 3 + cpdlcFont2Dimension.width * (2 - callsignPrefix.length)}
          y={2}
          text={callsignPrefix}
          fontName={cpdlcFont2}
          style={{ fontName: cpdlcFont2 }}
          zIndex={1}
        />
      )}
      <BitmapText
        eventMode="none"
        text={fp.cid ?? ""}
        x={2}
        y={2}
        tint={selected ? 0 : tint}
        style={{ fontName: font2, tint: selected ? 0 : tint }}
      />
      <BitmapText
        eventMode="none"
        text={`${fp.callsign}${showSatCommIndicator && EramFlightplan.isSatCommEquipped(fp) ? "*" : ""}${!owner ? "(UNK)" : owner === sectorId ? "" : `(${ownerShort ?? "UNK"})`
          }`}
        x={font2Dimension.width * 3 + cpdlcFont2Dimension.width * 2 + 2}
        y={2}
        tint={selected ? 0 : tint}
        style={{ fontName: font2, tint: selected ? 0 : tint }}
      />
    </Container>
  );
};

type AclRowActualProps = {
  trackId?: TrackId;
  owner?: SectorId | null;
  ownerShort?: string | null;
  sectorTrack: EramSectorTrack | null;
  fp: EramFlightplan;
  edstFp: EdstFpData;
  cpdlcSession?: EramCpdlcSession;
};

/**
 * Single ACL row
 */
const AclRowActual = React.memo(
  ({ fp, edstFp, trackId, owner, ownerShort, sectorTrack, cpdlcSession }: AclRowActualProps) => {
    const dispatch = useRootDispatch();
    const asel = useRootSelector((state) => aircraftIsAselSelector(state, fp.id));
    const manualPosting = useRootSelector(aclPostingModeSelector);
    const toolOptions = useRootSelector(toolsOptionsSelector);
    const { positions, widths, hiddenColumns } = useTableColumnPositions<AclRowField | AdditionalFields>();
    const coordination = useRootSelector((state) =>
      trackId ? trackCoordinationDataSelector(state, trackId) : null,
    );
    const [displayScratchHdg, setDisplayScratchHdg] = useState(false);
    const [displayScratchSpd, setDisplayScratchSpd] = useState(false);
    const showSatCommIndicator = useRootSelector(showSatCommIndicatorSelector);

    const fpId = fp.id;

    const formattedRoute = formatRoute(fp.route, fp.departure, fp.destination, fp.routeIsTruncated);
    // coral box indicates that aircraft is assigned an altitude in RVSM airspace but equipment says it is not RVSM approved
    const showCoralBox = !!(
      !EramFlightplan.isRvsmEquipped(fp) &&
      fp.assignedAltitude?.simple &&
      fp.assignedAltitude.simple > 280 &&
      toolOptions.nonRvsmIndicator
    );

    const isSelected = useCallback(
      (field: AclRowField) => {
        return asel?.fpId === fpId && asel?.field === field;
      },
      [fpId, asel?.fpId, asel?.field],
    );

    const handleMouseDown = useStableCallback(
      (element: DisplayObject, field: AclRowField, menu?: EdstAselMenu) => {
        dispatch(aircraftSelect(fpId, field, menu));
        if (menu && !isSelected(field)) {
          dispatch(openMenuThunk(menu, element));
        }
      },
    );

    const handleRouteClick = (element: DisplayObject) => {
      if (edstFp.routeDisplay === "HOLD_ANNOTATIONS_DISPLAY_OPTION") {
        handleMouseDown(element, "ROUTE_ACL_ROW_FIELD", "HOLD_MENU");
      } else {
        handleMouseDown(element, "ROUTE_ACL_ROW_FIELD", "ROUTE_MENU");
      }
    };

    const handleHotboxMouseDown: FederatedEventHandler = (event) => {
      switch (event.button) {
        case 0:
          eramHubConnection.updateEdstFpData(fpId, {
            showFreeText: !edstFp.showFreeText,
          });
          break;
        case 1:
          eramHubConnection.updateEdstFpData(fpId, { spa: !edstFp.spa });
          break;
        case 2:
          eramHubConnection.updateEdstFpData(fpId, {
            highlight: !edstFp.highlight,
          });
          break;
        default:
          break;
      }
    };

    const toggleVci = () => {
      if (!edstFp?.acknowledged) {
        eramHubConnection.updateEdstFpData(fpId, { acknowledged: true });
      } else {
        dispatch(processEramMessage(stringToParsedTokenArray(`//${fp.cid ?? fp.callsign}`)));
      }
    };

    const handleHoldClick: FederatedEventHandler = (event) => {
      switch (event.button) {
        case 0:
          if (!coordination?.holdAnnotations) {
            handleMouseDown(event.target as DisplayObject, "ROUTE_ACL_ROW_FIELD", "HOLD_MENU");
          } else {
            eramHubConnection.updateEdstFpData(fpId, {
              routeDisplay: !edstFp.routeDisplay ? "HOLD_ANNOTATIONS_DISPLAY_OPTION" : null,
            });
          }
          break;
        case 1:
          handleMouseDown(event.target as DisplayObject, "ROUTE_ACL_ROW_FIELD", "HOLD_MENU");
          break;
        case 2:
          if (coordination?.holdAnnotations) {
            handleMouseDown(event.target as DisplayObject, "ROUTE_ACL_ROW_FIELD", "CANCEL_HOLD_MENU");
          }
          break;
        default:
          break;
      }
    };

    const handleRemarksClick: FederatedEventHandler = (event) => {
      if (!sectorTrack?.communicationStatus && !manualPosting) {
        eramHubConnection.updateEdstFpData(fpId, { acknowledged: true });
      }
      switch (event.button) {
        case 0:
          eramHubConnection.updateEdstFpData(fpId, {
            routeDisplay:
              !(edstFp.routeDisplay === "REMARKS_DISPLAY_OPTION") && fp.remarks.length > 0
                ? "REMARKS_DISPLAY_OPTION"
                : null,
            remarksChecked: true,
          });
          break;
        default:
          break;
      }
    };

    const handleFidClick: FederatedEventHandler = (event) => {
      const now = Date.now();
      switch (event.button) {
        case 2:
          if (now - (edstFp.pendingRemoval ?? now) > REMOVAL_TIMEOUT) {
            eramHubConnection.updateEdstFpData(fpId, { deleted: true });
          }
          break;
        default:
          if (!manualPosting && event.detail === 2 && !edstFp.acknowledged) {
            eramHubConnection.updateEdstFpData(fpId, { acknowledged: true });
          }
          handleMouseDown(event.target as DisplayObject, "FID_ACL_ROW_FIELD");
          break;
      }
    };

    const handleHeadingClick: FederatedEventHandler = (event) => {
      event.preventDefault();
      if (coordination) {
        switch (event.button) {
          case 0:
            handleMouseDown(event.target as DisplayObject, "HDG_ACL_ROW_FIELD", "HEADING_MENU");
            break;
          case 1:
            if (edstFp.scratchHdg && (displayScratchHdg || !coordination.heading)) {
              // const promotedHdg = "LRH".includes(entry.scratchpadHeading.slice(-1)) ? entry.scratchpadHeading : `H${entry.scratchpadHeading}`;
            }
            break;
          case 2:
            if (edstFp.scratchHdg && (displayScratchHdg || !coordination.heading)) {
              eramHubConnection.updateEdstFpData(fpId, { scratchHdg: null });
            } else if (coordination.heading) {
              dispatch(processEramMessage(stringToParsedTokenArray(`QS */ ${fp.cid}`)));
            }
            break;
          default:
            break;
        }
      }
    };

    const handleSpeedClick: FederatedEventHandler = (event) => {
      event.preventDefault();
      if (coordination) {
        switch (event.button) {
          case 0:
            handleMouseDown(event.target as DisplayObject, "SPD_ACL_ROW_FIELD", "SPEED_MENU");
            break;
          case 1:
            if (edstFp.scratchSpd && (displayScratchSpd || !coordination.speed)) {
              // const promotedSpd = entry.scratchpadSpeed.slice(0, 1) === "M" ? entry.scratchpadSpeed : `S${entry.scratchpadSpeed}`;
            }
            break;
          case 2:
            if (edstFp.scratchSpd && (displayScratchSpd || !coordination.speed)) {
              eramHubConnection.updateEdstFpData(fpId, { scratchSpd: null });
            } else if (coordination.speed) {
              dispatch(processEramMessage(stringToParsedTokenArray(`QS /* ${fp.cid}`)));
            }
            break;
          default:
            break;
        }
      }
    };

    const {
      VCI: vciX,
      ALERT_RED: alertRedX,
      ALERT_YELLOW: alertYellowX,
      ALERT_ORANGE: alertOrangeX,
      FID_ACL_ROW_FIELD: flidX,
      PA_ACL_ROW_FIELD: paX,
      TYPE_ACL_ROW_FIELD: typeX,
      ALT_ACL_ROW_FIELD: altX,
      CODE_ACL_ROW_FIELD: codeX,
      HDG_ACL_ROW_FIELD: hdgX,
      SPD_ACL_ROW_FIELD: spdX,
      REMARKS_TOGGLE_BOX: remarksToggleBoxX,
      ROUTE_ACL_ROW_FIELD: routeX,
    } = positions;
    const spaIndicatorX = paX + widths.PA_ACL_ROW_FIELD + 2;
    const hotboxX = spaIndicatorX + font2Dimension.width + 6;

    const { FID_ACL_ROW_FIELD: flidWidth, HDG_ACL_ROW_FIELD: hdgWidth } = widths;

    const vciFontName = sectorTrack?.communicationStatus !== null ? eramCpdlcFontNameMap[2] : font2;

    const tint = !trackId ? 0x695547 : defaultTint;

    // TODO: format hold text
    const routeDisplayText =
      edstFp.routeDisplay === "HOLD_ANNOTATIONS_DISPLAY_OPTION"
        ? "HOLD"
        : edstFp.routeDisplay === "REMARKS_DISPLAY_OPTION"
          ? fp.remarks
          : formattedRoute;

    const routeWidth = Math.min(widths.ROUTE_ACL_ROW_FIELD, font2Dimension.width * routeDisplayText.length + 4);

    let altText = "";
    if (fp.assignedAltitude?.simple) {
      if (coordination?.localInterimAltitude) {
        altText = `${coordination.localInterimAltitude.toString()}L`;
      } else if (coordination?.interimAltitude) {
        altText = `${coordination.interimAltitude.altitude.toString()}${coordination.interimAltitude.type}`;
      }
      altText += fp.assignedAltitude.simple.toString();
    }
    if (fp.assignedAltitude?.block) {
      altText = `${fp.assignedAltitude.block.min.toString()}B${fp.assignedAltitude.block.max.toString()}`;
    }

    const { setInputValue: setFreeformTextInputValue, ...inputProps } = useInputProps(
      `ACL/table/row/${fpId}/freeformtext`,
      edstFp?.freeformText ?? "",
      (value) => {
        void eramHubConnection.updateEdstFpData(fpId, {
          freeformText: value,
        });
        inputProps.setInputFocus(false);
      },
      {
        initialShowInput: false,
      },
    );

    useEffect(() => {
      if (edstFp.freeformText) {
        setFreeformTextInputValue(edstFp.freeformText);
      }
    }, [edstFp.freeformText, setFreeformTextInputValue]);

    useEffect(() => {
      if (!edstFp.showFreeText && edstFp.freeformText !== inputProps.value) {
        void eramHubConnection.updateEdstFpData(fpId, {
          freeformText: inputProps.value,
        });
        inputProps.setInputFocus(false);
      }
    }, [edstFp.freeformText, edstFp.showFreeText, fpId, inputProps, inputProps.value]);

    // TODO: include CPDLC fields

    const highlightWidth = Math.max(
      routeX + routeWidth - flidX + 1,
      typeX - flidX + font2Dimension.width * inputProps.value.length,
    );
    const freeTextWidth = highlightWidth + (flidX - typeX) - 2;

    return (
      <Container sortableChildren eventMode="static">
        {edstFp.highlight && (
          <Graphics
            eventMode="none"
            zIndex={0}
            draw={(g) => {
              g.clear();
              g.beginFill(0x414141)
                .drawRect(
                  flidX - 1,
                  -1,
                  highlightWidth,
                  (font2Dimension.height + 5) * (edstFp.showFreeText ? 2 : 1),
                )
                .endFill();
            }}
          />
        )}
        <Container zIndex={1} eventMode="static">
          <TableField
            key="VCI"
            x={vciX}
            fontName={vciFontName}
            text={
              !edstFp?.acknowledged
                ? "N"
                : sectorTrack?.communicationStatus
                  ? vciTextMap[sectorTrack.communicationStatus]
                  : " "
            }
            hoverTint={edstFp?.acknowledged && sectorTrack?.communicationStatus ? 0x00ad00 : 0xf0f0f0}
            onmousedown={toggleVci}
          />
          <TableField key="ALERT_RED" x={alertRedX} text=" " disabled borderTint={0xadadad} />
          <TableField key="ALERT_YELLOW" x={alertYellowX} text=" " disabled borderTint={0xadadad} />
          <TableField key="ALERT_ORANGE" x={alertOrangeX} text=" " disabled borderTint={0xadadad} />
          <FlidCol
            x={flidX}
            width={flidWidth}
            height={font2Dimension.height + 2}
            onmousedown={handleFidClick}
            selected={isSelected("FID_ACL_ROW_FIELD")}
            tint={tint}
            fp={fp}
            edstFp={edstFp}
            cpdlcSession={cpdlcSession}
            owner={owner}
            ownerShort={ownerShort}
          />
          <TableField key="PO" x={paX} tint={tint} text="" disabled />
          {edstFp.spa && (
            <TableField key="SPA_INDICATOR" x={spaIndicatorX} text="^" borderTint={0x575757} disabled />
          )}
          <TableField
            key="HOTBOX"
            x={hotboxX}
            text={edstFp.freeformText ? "*" : " "}
            borderTint={0x575757}
            onmousedown={handleHotboxMouseDown}
          />
          <TableField
            key="TYPE"
            tint={tint}
            x={typeX}
            text={
              hiddenColumns.includes("TYPE_ACL_ROW_FIELD") ? "" : `${fp.aircraftType}/${fp.equipmentQualifier}`
            }
            onmousedown={(event) => handleMouseDown(event.currentTarget as DisplayObject, "TYPE_ACL_ROW_FIELD")}
            selected={isSelected("TYPE_ACL_ROW_FIELD")}
            disabled={hiddenColumns.includes("TYPE_ACL_ROW_FIELD")}
          />
          <TableField
            key="ALT"
            tint={tint}
            x={altX}
            text={altText}
            selected={isSelected("ALT_ACL_ROW_FIELD")}
            onmousedown={(event) =>
              handleMouseDown(event.currentTarget as DisplayObject, "ALT_ACL_ROW_FIELD", "ALTITUDE_MENU")
            }
          />
          <TableField
            key="CODE"
            tint={tint}
            x={codeX}
            text={hiddenColumns.includes("CODE_ACL_ROW_FIELD") || !fp.squawk ? "" : ` ${fp.squawk}`}
            selected={isSelected("CODE_ACL_ROW_FIELD")}
            onmousedown={(event) => handleMouseDown(event.currentTarget as DisplayObject, "CODE_ACL_ROW_FIELD")}
            disabled={hiddenColumns.includes("CODE_ACL_ROW_FIELD")}
          />
          <TableField
            key="HDG"
            tint={tint}
            x={hdgX}
            text={hiddenColumns.includes("HDG_ACL_ROW_FIELD") ? "" : coordination?.heading ?? ""}
            disabled={hiddenColumns.includes("HDG_ACL_ROW_FIELD")}
            textX={2 + font2Dimension.width * Math.max(0, 4 - (coordination?.heading?.length ?? 0))}
            selected={isSelected("HDG_ACL_ROW_FIELD")}
            width={3 + font2Dimension.width * (hiddenColumns.includes("HDG_ACL_ROW_FIELD") ? 2 : 4)}
            onmousedown={handleHeadingClick}
          />
          <TableField key="HDG_SPD_SLASH" x={hdgX + hdgWidth + 2} text="/" disabled />
          <TableField
            key="SPD"
            tint={tint}
            x={spdX}
            text={hiddenColumns.includes("SPD_ACL_ROW_FIELD") ? "" : coordination?.speed ?? ""}
            disabled={hiddenColumns.includes("SPD_ACL_ROW_FIELD")}
            textX={2}
            selected={isSelected("SPD_ACL_ROW_FIELD")}
            width={3 + font2Dimension.width * (hiddenColumns.includes("SPD_ACL_ROW_FIELD") ? 2 : 4)}
            onmousedown={handleSpeedClick}
          />
          <TableField
            key="REMARKS_TOGGLE_BOX"
            x={remarksToggleBoxX}
            text={fp.remarks ? "*" : " "}
            onmousedown={handleRemarksClick}
          />
          <TableField
            key="ROUTE"
            tint={tint}
            x={routeX}
            textX={2}
            text={routeDisplayText}
            selected={isSelected("ROUTE_ACL_ROW_FIELD")}
            maxWidth={routeWidth}
            onmousedown={(event) => handleRouteClick(event.currentTarget as DisplayObject)}
          />
        </Container>
        {edstFp.showFreeText && (
          <EramInput
            {...inputProps}
            zIndex={1}
            x={typeX}
            y={font2Dimension.height + 6}
            width={freeTextWidth}
            tint={tint}
            highlightColor={defaultTint}
          />
        )}
      </Container>
    );
  },
);
