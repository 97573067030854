import React from "react";
import { View } from "components/utils/View";
import { ViewOptionContextProvider, CpdlcHistory as BaseCpdlcHistory } from "@poscon/shared-frontend";
import { useRootSelector } from "~redux/hooks";
import { viewOptionSelector } from "~redux/slices/viewOptionSlice";

const view = "CPDLC_HIST";

/*
 * TI6110.100b - section 4.2.8.1
 * Message History View Format
 * The Message History View displays all **closed** CPDLC messages exchanged between
 * the local facility and the specified aircraft.
 */
export const CpdlcHistory = () => {
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <BaseCpdlcHistory ViewComponent={View} />
    </ViewOptionContextProvider>
  );
};
