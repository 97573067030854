import type { Coordinate } from "@poscon/shared-types";
import * as turf from "@turf/turf";
import { geoMercator } from "d3-geo";
import { Matrix, Point, Rectangle } from "pixi.js";
import type { ListenerEntry } from "types/listenerEntry";
import { startListening } from "~redux/listenerMiddleware";
import { dposConfigSelector, viewDimensionSelector, viewIsFullscreenSelector } from "~redux/slices/edstSlice";
import { titleBarHeight } from "components/ViewTitleBar";
import { font2Dimension, headerRowHeight } from "~/utils/constants";
import { BaseStore } from "@poscon/shared-frontend";
import { gpdCenterOverrideSelector, gpdScaleSelector } from "~redux/slices/gpdSlice";

export type GpdState = {
  rect: Rectangle;
  range: number;
  matrix: Matrix;
  projection: ReturnType<typeof geoMercator>;
  getLonLatFromPixelCoord: (coord: Coordinate) => Coordinate;
  getPixelCoordFromLonLat: (coord: Coordinate) => Coordinate;
  getWindowCoordinatesFromSdCoordinates: (coord: Coordinate) => Coordinate;
};

const listeners: ListenerEntry[] = [
  {
    predicate: (action, state, prevState) =>
      dposConfigSelector(state) !== dposConfigSelector(prevState) ||
      gpdScaleSelector(state) !== gpdScaleSelector(prevState) ||
      gpdCenterOverrideSelector(state) !== gpdCenterOverrideSelector(prevState),
    effect: (action, { getState }) => {
      const state = getState();
      const gpdCenter = dposConfigSelector(state).gpdParams.gpdCenter;
      const centerOverride = gpdCenterOverrideSelector(state);
      gpdStore.setCenter(gpdCenter);
      gpdStore.setMatrix(gpdScaleSelector(state), centerOverride[0], centerOverride[1]);
    },
  },
  {
    predicate: (action, state, prevState) =>
      viewDimensionSelector(state, "GPD") !== viewDimensionSelector(prevState, "GPD") ||
      viewIsFullscreenSelector(state, "WIND") !== viewIsFullscreenSelector(prevState, "GPD"),
    effect: (action, { getState }) => {
      const state = getState();
      const isFullscreen = viewIsFullscreenSelector(state, "GPD");
      gpdStore.isFullscreen = isFullscreen;
      if (isFullscreen) {
        gpdStore.setDimensions({
          width: window.innerWidth - 3,
          height: window.innerHeight - headerRowHeight - 6,
        });
      } else {
        gpdStore.setDimensions(viewDimensionSelector(state, "GPD"));
      }
    },
  },
];

listeners.forEach((listener) => startListening(listener));

class GpdStore extends BaseStore<GpdState> {
  rect = new Rectangle(0, 0, 0, 0);

  private matrix = Matrix.IDENTITY;

  projection = geoMercator().scale(14000).translate([0, 0]);

  private _isFullscreen = false;

  protected state = this.computeLatestState();

  constructor() {
    super();
    window.addEventListener("resize", () => {
      if (this.isFullscreen) {
        this.setDimensions({ width: window.innerWidth - 3, height: window.innerHeight - headerRowHeight - 6 });
      }
    });
  }

  get isFullscreen(): boolean {
    return this._isFullscreen;
  }

  set isFullscreen(value: boolean) {
    this._isFullscreen = value;
  }

  get range() {
    return Math.round(this.pixelLen * this.rect.width);
  }

  setMatrix(scale: number, translateX = 0, translateY = 0) {
    this.matrix = new Matrix(scale, 0, 0, scale, translateX, translateY);
    this.sync();
  }

  setCenter(center: Coordinate) {
    this.projection.center(center);
    this.sync();
  }

  setDimensions(dimensions: { width: number; height: number }) {
    this.rect.width = dimensions.width;
    this.rect.height = dimensions.height - (titleBarHeight + 3 + font2Dimension.height * 3 + 8);
    this.projection
      .scale(12000)
      .translate([dimensions.width / 2, dimensions.height / 2])
      .center(this.projection.center());
    this.sync();
  }

  // return lon/lat from pixel coordinate
  getLonLatFromPixelCoord(coordinate: Coordinate): Coordinate {
    const point = new Point(coordinate[0], coordinate[1]);
    this.matrix.applyInverse(point, point);
    return this.projection.invert!([point.x, point.y])!;
  }

  // return pixel coordinate from lon/lat
  getPixelCoordFromLonLat(coordinate: Coordinate): Coordinate {
    const projectedPoint = this.projection(coordinate)!;
    const point = new Point(projectedPoint[0], projectedPoint[1]);
    this.matrix.apply(point, point);
    return [point.x, point.y];
  }

  get pixelLen() {
    return turf.distance(this.getLonLatFromPixelCoord([0, 0]), this.getLonLatFromPixelCoord([1, 0]), {
      units: "nauticalmiles",
    });
  }

  nmToPx(nm: number) {
    return Math.floor(nm / this.pixelLen);
  }

  getCoordinates(coord: Coordinate): Coordinate {
    return [coord[0] - this.rect.x, coord[1] - this.rect.y];
  }

  getWindowCoordinatesFromSdCoordinates(coord: Coordinate): Coordinate {
    return [coord[0] + this.rect.x, coord[1] + this.rect.y];
  }

  protected computeLatestState() {
    const projection = this.projection;
    const rect = this.rect;
    const range = this.range;
    return {
      rect,
      range,
      projection,
      matrix: this.matrix,
      getLonLatFromPixelCoord: this.getLonLatFromPixelCoord.bind(this),
      getPixelCoordFromLonLat: this.getPixelCoordFromLonLat.bind(this),
      getWindowCoordinatesFromSdCoordinates: this.getWindowCoordinatesFromSdCoordinates.bind(this),
    };
  }
}

export const gpdStore = new GpdStore();
