import React from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import {
  depPostingModeSelector,
  depSortOptionSelector,
} from "~redux/slices/depSlice";
import { closeView, depAselSelector, setAsel } from "~redux/slices/edstSlice";
import {
  DepSortMethodValues,
  stringToParsedTokenArray,
  isDepRowField,
} from "@poscon/shared-types";
import type { HeaderComponentProps } from "components/utils/FullscreenView";
import { EdstButton } from "components/utils/EdstButton";
import { titleBarHeight, ViewTitleBar } from "components/ViewTitleBar";
import { openViewThunk } from "~redux/thunks/openViewThunk";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { defaultTint, font2, font2Dimension } from "~/utils/constants";
import type { DisplayObject } from "pixi.js";
import { eramHubConnection } from "~/eramHubConnection";
import { useInputProps } from "@poscon/shared-frontend";
import { EdstInput } from "components/utils/EdstInput";
import { aclHeaderHeight } from "components/acl/AclHeader";

export const depHeaderHeight = titleBarHeight + font2Dimension.height * 3 + 11;

/**
 * DEP title bar and header row with add/find input field
 */
export const DepHeader = ({
  focused,
  toggleFullscreen,
  startDrag,
  width,
}: HeaderComponentProps) => {
  const asel = useRootSelector(depAselSelector);
  const selectedSortOption = useRootSelector(depSortOptionSelector);
  const postingMode = useRootSelector(depPostingModeSelector);
  const dispatch = useRootDispatch();
  const addFindInputProps = useInputProps(
    `DEP/header/addFind`,
    "",
    (searchStr) => {
      dispatch(
        processEramMessage(stringToParsedTokenArray(`UU D ${searchStr}`)),
      );
    },
    {
      maxLength: 7,
      focusOnMount: false,
    },
  );

  return (
    <Container>
      <ViewTitleBar
        width={width}
        focused={focused}
        toggleFullscreen={toggleFullscreen}
        startDrag={startDrag}
        closeView={() => {
          if (isDepRowField(asel?.field)) {
            setAsel(null);
          }
          dispatch(closeView("DEP"));
        }}
        text={[
          "Departure List",
          `${DepSortMethodValues[selectedSortOption]}`,
          `${postingMode === "MANUAL" ? "Manual" : "Automatic"}`,
        ]}
      />
      <Container y={titleBarHeight + 3}>
        <Graphics
          draw={(graphics) => {
            graphics.clear();
            graphics
              .lineStyle(1, 0xadadad)
              .moveTo(1, font2Dimension.height + 8)
              .lineTo(width, font2Dimension.height + 8)
              .moveTo(1, aclHeaderHeight - (titleBarHeight + 3))
              .lineTo(width, aclHeaderHeight - (titleBarHeight + 3));
          }}
        />
        <EdstButton
          x={4}
          disabled={asel === null}
          onmousedown={(e) => {
            dispatch(openViewThunk("PLAN_OPTIONS", e.target as DisplayObject));
          }}
          text="Plan Options..."
        />
        <EdstButton
          x={4 + font2Dimension.width * 15 + 8 * 2}
          text="Sort..."
          onmousedown={(e) => {
            dispatch(openViewThunk("DEP_SORT_MENU", e.target as DisplayObject));
          }}
        />
        <EdstButton
          x={4 + font2Dimension.width * 22 + 8 * 4}
          text="Posting Mode"
          onmousedown={() => eramHubConnection.emit("toggleDepPostingMode")}
        />
        <EdstButton
          x={4 + font2Dimension.width * 34 + 8 * 6}
          text="Template..."
          onmousedown={(e) => {
            dispatch(openViewThunk("TEMPLATE_MENU", e.target as DisplayObject));
          }}
        />
      </Container>
      <Container y={depHeaderHeight - (font2Dimension.height + 13)}>
        <BitmapText
          text="ADD/FIND"
          y={4}
          x={font2Dimension.width}
          style={{ tint: defaultTint, fontName: font2 }}
        />
        <EdstInput
          {...addFindInputProps}
          x={font2Dimension.width * 11}
          width={font2Dimension.width * 9}
        />
      </Container>
    </Container>
  );
};
