import type { ComponentType } from "react";
import React from "react";
import type { FlightplanId, EdstFpData } from "@poscon/shared-types";
import { Container, Graphics } from "@pixi/react";
import { font2Dimension } from "~/utils/constants";
import type { ColorSource } from "pixi.js";

export const ListSeparator = ({
  x = 0,
  y = 0,
  width,
  thickness = 2,
  color = 0xe3cfb4,
}: {
  x?: number;
  y?: number;
  width: number;
  thickness?: number;
  color?: ColorSource;
}) => (
  <Graphics
    x={x}
    y={y}
    draw={(g) => {
      g.clear();
      g.lineStyle(thickness, color);
      g.moveTo(0, 0);
      g.lineTo(width, 0);
    }}
  />
);

type MapRowProps = {
  y0?: number;
  list: string[];
  width: number;
  edstFlightplans: Record<FlightplanId, EdstFpData>;
  Component: ComponentType<{ fpId: FlightplanId }>;
};
export const ListMapper = ({ y0 = 0, list, Component, width, edstFlightplans }: MapRowProps) => {
  let y = 0;

  return (
    <Container y={y0}>
      {list.slice(0, 30).map((fpId, i) => {
        const _y = y;
        y += font2Dimension.height + 6;
        if (edstFlightplans[fpId]?.showFreeText) {
          y += font2Dimension.height + 6;
        }
        const showSep = i % 3 === 2;
        if (showSep) {
          y += 2;
        }
        return (
          <Container key={fpId} y={_y}>
            <Component key={fpId} fpId={fpId} />
            {showSep && (
              <ListSeparator
                y={(font2Dimension.height + 5) * (edstFlightplans[fpId]?.showFreeText ? 2 : 1)}
                width={width}
                thickness={1}
                color={0x252525}
              />
            )}
          </Container>
        );
      })}
    </Container>
  );
};
