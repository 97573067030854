import React, { useRef } from "react";
import type { ColorSource, Graphics as PixiGraphics } from "pixi.js";
import { Container, Graphics } from "@pixi/react";
import { EramInput, useFocused } from "@poscon/shared-frontend";
import { bottomRightBorderColor, defaultTint, font2Dimension, topLeftBorderColor } from "~/utils/constants";

export const defaultInputBoxPadding = 4;

type EdstInputProps = {
  name?: string;
  value: string;
  x?: number;
  y?: number;
  width: number;
  height?: number;
  zIndex?: number;
  chunkSize?: number;
  isActive: boolean;
  cursorPosition: number;
  textColor?: ColorSource;
  padding?: number;
};
export const EdstInput = ({ x, y, zIndex, padding = defaultInputBoxPadding, ...props }: EdstInputProps) => {
  const ref = useRef<PixiGraphics>(null);
  const focused = useFocused(ref);
  return (
    <Container zIndex={zIndex} x={x} y={y} eventMode="static" ref={ref}>
      <Graphics
        zIndex={0}
        eventMode="static"
        draw={(graphics) => {
          graphics.clear();
          if (focused) {
            graphics.lineStyle(1, 0xffffff, 1, 1);
            graphics.drawRect(
              0,
              0,
              props.width + padding * 2,
              (props.height ?? font2Dimension.height) + padding * 2,
            );
          }
          graphics.lineStyle(2, bottomRightBorderColor, 1, 0);
          graphics.beginFill(0x000000);
          graphics.drawRect(
            0,
            0,
            props.width + padding * 2,
            (props.height ?? font2Dimension.height) + padding * 2,
          );
          graphics.endFill();
          graphics
            .lineStyle(2, topLeftBorderColor, 1, 0)
            .moveTo(props.width + padding * 2, 0)
            .lineTo(props.width + padding * 2, (props.height ?? font2Dimension.height) + padding * 2)
            .lineTo(0, (props.height ?? font2Dimension.height) + padding * 2);
        }}
      />
      <EramInput x={padding} y={padding} zIndex={1} tint={defaultTint} {...props} />
    </Container>
  );
};
