import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { View } from "components/utils/View";
import {
  useViewOptionSelected,
  colorNameMap,
  computeColor,
  eramFontDimensionMap,
  eramFontNameMap,
  useFocused,
  useMetar,
  createItemOption,
  ViewItemOptionContainer,
  EramFontSize,
  metarAirportsSelector
} from "@poscon/shared-frontend";
import { brightOption, fontOption, linesOption, viewOptionSelector } from "~redux/slices/viewOptionSlice";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import { stringToParsedTokenArray } from "@poscon/shared-types";
import { BitmapText, Container, Graphics } from "@pixi/react";
import type { Container as PixiContainer } from "pixi.js";
import { Rectangle } from "pixi.js";
import { ViewOptionContextProvider, useViewOptions } from "contexts/viewOptionContext";

const view = "WX_REPORT";

export const optionMap = {
  lines: linesOption(view, 2, 20),
  font: fontOption(view),
  bright: brightOption(view),
};

type MetarRowProps = {
  airport: string;
};
export const MetarRow = ({ airport }: MetarRowProps) => {
  const dispatch = useRootDispatch();
  const { selected, toggleViewOption } = useViewOptionSelected(`${view}/${airport}`);
  const ref = useRef<PixiContainer>(null);
  const focused = useFocused(ref);
  const viewOptions = useViewOptions(view);
  const itemOptions = [
    createItemOption(`DELETE ${airport}`, () =>
      dispatch(processEramMessage(stringToParsedTokenArray(`WR ${airport}`))),
    ),
  ];
  const fontSize = viewOptions.font as EramFontSize;
  const fontName = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontName];

  const { metar } = useMetar(airport);

  const text = metar ?? "-M-";
  const lines = 1 + Math.floor(text.length / 35);

  const width = 37 * fontDimension.width + 4;
  const height = fontDimension.height * lines + 2;

  const alpha = (viewOptions.bright * 0.8) / 100 + 0.2;

  const tint = selected ? 0 : computeColor(colorNameMap.white, viewOptions.bright / 100);
  const style = { fontName, tint };

  const bgColor = computeColor(selected ? colorNameMap.grey : colorNameMap.black, selected ? alpha : 1);

  return (
    <Container>
      <Container eventMode="static" ref={ref} sortableChildren>
        <Graphics
          hitArea={new Rectangle(0, 0, width, height)}
          eventMode="static"
          zIndex={1}
          draw={(graphics) => {
            graphics.clear();
            // eslint-disable-next-line no-nested-ternary
            graphics.lineStyle(1, focused ? 0xffffff : selected ? bgColor : 0x000000);
            graphics
              .beginFill(bgColor, selected ? 1 : 0)
              .drawRect(0, 0, width, height)
              .endFill();
          }}
          onmousedown={() => {
            toggleViewOption();
          }}
        />
        <BitmapText
          x={fontDimension.width * 2}
          y={2}
          maxWidth={35 * fontDimension.width}
          zIndex={3}
          text={text}
          eventMode="none"
          fontName={fontName}
          tint={tint}
          style={style}
        />
      </Container>
      {selected && <ViewItemOptionContainer xOffset={width} options={itemOptions} />}
    </Container>
  );
};

export const WxReport = () => {
  const airports = useRootSelector(metarAirportsSelector);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));

  const fontDimension = eramFontDimensionMap[eramFontNameMap[viewOptions.font as EramFontSize]];

  const height = airports.length > 0 ? (fontDimension.height + 2.5) * 2 * airports.length + 8 : 0;

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View width={40} height={height} view="WX_REPORT" optionMap={optionMap}>
        {airports.length > 0 && (
          <Container x={4} y={4} eventMode="static">
            {airports.map((airport, index) => (
              <Container
                key={`${airport}-${index}`}
                eventMode="static"
                x={8}
                y={(fontDimension.height * 2 + 4) * index + 2}
              >
                <MetarRow key={`${airport}-${index}`} airport={airport} />
              </Container>
            ))}
          </Container>
        )}
      </View>
    </ViewOptionContextProvider>
  );
};
