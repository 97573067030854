import React, { useRef } from "react";
import { useRootSelector } from "~redux/hooks";
import { viewOptionSelector } from "~redux/slices/viewOptionSlice";
import { View } from "components/utils/View";
import { sectorIdSelector, useViewOptionSelected } from "@poscon/shared-frontend";

const view = "SIGMETS";

type SigmetRowProps = {
  sigmetEntry: any;
  onSuppress: () => void;
};
const SigmetRow = ({ sigmetEntry, onSuppress }: SigmetRowProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const { selected: showOptions, openViewOption } = useViewOptionSelected(`${view}/sigmetEntry/${sigmetEntry.id}`);

  const onmousedown = () => {
    openViewOption();
  };

  return null;
  // return (
  //   <>
  //     <div
  //       className={clsx(floatingStyles.row, "tm6", {
  //         selected,
  //         [floatingStyles.suppressed]: sigmetEntry.suppressed,
  //       })}
  //       ref={ref}
  //       style={{ "--brightness": viewOptions.brightness / 100 }}
  //       onmousedown={onmousedown}
  //     >
  //       {sigmetEntry.text}
  //     </div>
  //     {/* {selected && showOptions && rect && ()} */}
  //   </>
  // );
};

export const SigmetView = () => {
  const sectorId = useRootSelector(sectorIdSelector);
  const sigmetList: any = {};

  return (
    <View
      title={`SIGMETS SECTOR ${sectorId?.split(".").at(-1) ?? ""}`}
      width={130}
      height={0}
      view="SIGMETS"
    >
      {Object.values(sigmetList).length > 0 && (
        <div className="scrollContainer bounded-scroll barLeft">
          {Object.entries(sigmetList).map(
            ([sigmetId, sigmetEntry]) => (
              // (!sigmetEntry.suppressed || viewSuppressed) && (
              <SigmetRow
                key={sigmetId}
                sigmetEntry={sigmetEntry}
                onSuppress={() => {
                  // dispatch(
                  //   setSigmetSuppressed({
                  //     id: sigmetId,
                  //     value: !sigmetEntry.suppressed,
                  //   })
                  // );
                }}
              />
            ),
            // )
          )}
        </div>
      )}
    </View>
  );
};
