import React, { useCallback, useEffect, useState } from "react";
import type { CursorOverride } from "@poscon/shared-frontend";
import {
  CursorContextProvider as BaseCursorContextProvider,
  eramCursorCenterMap,
  errorTone,
  useCursorContext as UseBaseCursorContext,
} from "@poscon/shared-frontend";

type CursorContextValue = {
  setCursorOverride: (cursorOverride: CursorOverride | null) => void;
  onCounterError: (err: "MIN_VALUE" | "MAX_VALUE") => void;
  onTBHError: () => void;
  setShowRecenterCursor: (v: boolean) => void;
};

export const CursorContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [showRecenterCursor, setShowRecenterCursor] = useState(false);
  const [cursorOverride, setCursorOverride] = useState<CursorOverride | null>(null);

  useEffect(() => {
    if (cursorOverride) {
      const cursorName = `ERAM${cursorOverride}`;
      const cursorCenter = eramCursorCenterMap[cursorName]?.join(" ") ?? "";
      document.body.style.cursor = `url(/cursors/${cursorName}.png) ${cursorCenter}, auto`;
    } else {
      document.body.style.cursor = showRecenterCursor
        ? "url(/cursors/EDSTRecenterCursor.png) 13 13, auto"
        : `auto`;
    }
  }, [cursorOverride, showRecenterCursor]);

  const onCounterError = useCallback((err: "MIN_VALUE" | "MAX_VALUE") => {
    void errorTone.tryPlay();
    if (err === "MIN_VALUE") {
      setCursorOverride("InvalidSelectionE");
    } else {
      setCursorOverride("InvalidSelectionP");
    }
  }, []);

  const onTBHError = useCallback(() => {
    void errorTone.tryPlay();
    setCursorOverride("InvalidSelectionI");
  }, []);

  useEffect(() => {
    let timeout: ReturnType<typeof window.setTimeout> | null = null;
    if (cursorOverride) {
      timeout = setTimeout(() => {
        setCursorOverride(null);
      }, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [cursorOverride]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <BaseCursorContextProvider value={{ setCursorOverride, onCounterError, onTBHError, setShowRecenterCursor }}>
      {children}
    </BaseCursorContextProvider>
  );
};

export const useCursorContext = () => {
  return UseBaseCursorContext() as CursorContextValue;
};
