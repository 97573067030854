import React from "react";
import type { Graphics, Rectangle } from "pixi.js";
import type { Coordinate } from "@poscon/shared-types";
import { assert } from "@poscon/shared-types";
import type { AnchorDirection } from "hooks/useResizable";
import { useStableCallback } from "@poscon/shared-frontend";

type DragContextValue = {
  anyDragging: boolean;
  setAnyDragging: React.Dispatch<React.SetStateAction<boolean>>;
  draggingHandler: (event: MouseEvent, offset?: Coordinate) => void;
  resizeHandler: (
    event: MouseEvent,
    anchorX: AnchorDirection,
    anchorY: AnchorDirection,
    bounds: Rectangle,
  ) => void;
  getDraggingOutlinePositionAndDimension: () => {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  setDraggingOutlinePositionAndDimension: (x: number, y: number, width: number, height: number) => void;
  setDraggingOutlineVisible: (visible: boolean) => void;
};
export const DragContext = React.createContext<DragContextValue | null>(null);

type DragContextProviderProps = {
  children: React.ReactNode;
  draggingOutlineRef: React.RefObject<Graphics | null>;
};
export const DragContextProvider = ({ children, draggingOutlineRef }: DragContextProviderProps) => {
  const [anyDragging, setAnyDragging] = React.useState(false);

  const setDraggingOutlinePositionAndDimension = useStableCallback(
    (x: number, y: number, width: number, height: number) => {
      assert(draggingOutlineRef.current !== null, "draggingOutlineRef.current is null");
      draggingOutlineRef.current.x = x;
      draggingOutlineRef.current.y = y;
      draggingOutlineRef.current.clear();
      draggingOutlineRef.current.lineStyle(1, 0xffffff);
      draggingOutlineRef.current.drawRect(0, 0, width, height);
    },
  );

  const setDraggingOutlineVisible = useStableCallback((visible: boolean) => {
    assert(draggingOutlineRef.current !== null, "draggingOutlineRef.current is null");
    draggingOutlineRef.current.visible = visible;
  });

  const getDraggingOutlinePositionAndDimension = useStableCallback(() => {
    assert(draggingOutlineRef.current !== null, "draggingOutlineRef.current is null");
    return {
      x: draggingOutlineRef.current.x,
      y: draggingOutlineRef.current.y,
      height: draggingOutlineRef.current.height,
      width: draggingOutlineRef.current.width,
    };
  });

  const draggingHandler = useStableCallback((event: MouseEvent, offset: [number, number] = [0, 0]) => {
    assert(draggingOutlineRef.current !== null, "draggingOutlineRef.current is null");
    event.stopPropagation();
    draggingOutlineRef.current.x = event.pageX + offset[0];
    draggingOutlineRef.current.y = event.pageY + offset[1];
  });

  const resizeHandler = useStableCallback(
    (event: MouseEvent, anchorX: AnchorDirection, anchorY: AnchorDirection, bounds: Rectangle) => {
      assert(draggingOutlineRef.current !== null, "draggingOutlineRef.current is null");
      event.stopPropagation();
      const newX = anchorX === -1 ? event.pageX : bounds.left;
      const newY = anchorY === -1 ? event.pageY : bounds.top;
      let newWidth: number;
      switch (anchorX) {
        case -1:
          newWidth = bounds.width - (event.pageX - bounds.x);
          break;
        case 1:
          newWidth = event.pageX - bounds.x;
          break;
        default:
          newWidth = bounds.width;
      }
      let newHeight: number;
      switch (anchorY) {
        case -1:
          newHeight = bounds.height - (event.pageY - bounds.y);
          break;
        case 1:
          newHeight = event.pageY - bounds.y;
          break;
        default:
          newHeight = bounds.height;
      }
      setDraggingOutlinePositionAndDimension(newX, newY, newWidth, newHeight);
    },
  );

  return (
    <DragContext.Provider
      value={{
        anyDragging,
        setAnyDragging,
        setDraggingOutlinePositionAndDimension,
        setDraggingOutlineVisible,
        getDraggingOutlinePositionAndDimension,
        draggingHandler,
        resizeHandler,
      }}
    >
      {children}
    </DragContext.Provider>
  );
};

export const useDragContext = () => {
  const contextValue = React.useContext(DragContext);
  if (contextValue === null) {
    throw new Error("useDragContext must be used within a DragContextProvider");
  }
  return contextValue;
};

export const useAnyDragging = () => {
  const { anyDragging, setAnyDragging } = useDragContext();
  return { anyDragging, setAnyDragging };
};
