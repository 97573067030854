import React, { useState } from "react";
import type { ViewMenuProps } from "components/utils/ViewMenu";
import { ViewMenu } from "components/utils/ViewMenu";
import { useViewOptions } from "contexts/viewOptionContext";
import { eramFontNameMap, eramTextDimensionMap } from "@poscon/shared-frontend";

const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[2];
const fontName = eramFontNameMap[2];

export const ASViewMenu = (props: ViewMenuProps<"ALTIM_SET">) => {
  const [showTemplate, setShowTemplate] = useState(false);
  const viewOptions = useViewOptions(props.view);

  const options = {
    ...props.options,
    template: { ...props.options!.template!, onClick: () => setShowTemplate(true) },
  };

  const width = 5 * fontWidth * viewOptions.column;

  return (
    <ViewMenu {...props} options={!showTemplate ? options : {}} minWidth={showTemplate ? width : undefined}>
      {/* {showTemplate ? <AltimSetTemplate /> : null} */}
    </ViewMenu>
  );
};
