import React from "react";
import { Graphics } from "@pixi/react";
import type { ColorSource, Graphics as PixiGraphics } from "pixi.js";
import { colorNameMap, useFocused } from "@poscon/shared-frontend";
import { Rectangle } from "pixi.js";

type RadioButtonProps = {
  x?: number;
  y?: number;
  size?: number;
  borderSize?: number;
  borderTint?: ColorSource;
  selectedTint?: ColorSource;
  selected: boolean;
  onmousedown?: () => void;
};

export const RadioButton = ({
  x = 0,
  y = 0,
  selected,
  onmousedown,
  size = 8,
  borderSize = 1,
  borderTint = colorNameMap.darkGrey,
  selectedTint = colorNameMap.grey,
}: RadioButtonProps) => {
  const ref = React.useRef<PixiGraphics>(null);
  const focused = useFocused(ref);
  return (
    <Graphics
      ref={ref}
      x={x}
      y={y}
      eventMode="static"
      onmousedown={onmousedown}
      hitArea={new Rectangle(-size / 2, -size / 2, size, size)}
      draw={(graphics) => {
        graphics.clear();
        graphics.lineStyle(borderSize, focused ? colorNameMap.white : borderTint);
        graphics
          .beginFill(selected ? selectedTint : 0x000000)
          .drawCircle(0, 0, Math.floor(size / 2))
          .endFill();
      }}
    />
  );
};
