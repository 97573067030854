import React from "react";
import type { HeaderComponentProps } from "components/utils/FullscreenView";
import { useRootDispatch } from "~redux/hooks";
import { closeView as closeViewAction } from "~redux/slices/edstSlice";
import { ViewTitleBar } from "components/ViewTitleBar";

export const WindGridHeader = ({ focused, toggleFullscreen, startDrag, width }: HeaderComponentProps) => {
  const dispatch = useRootDispatch();
  const closeView = () => {
    dispatch(closeViewAction("WIND"));
  };

  return (
    <ViewTitleBar
      width={width}
      focused={focused}
      toggleFullscreen={toggleFullscreen}
      startDrag={startDrag}
      closeView={closeView}
      text={["WIND GRID DISPLAY"]}
    />
  );
};
