import type { Container as PixiContainer, Graphics as PixiGraphics } from "pixi.js";
import React, { useRef } from "react";
import { useFocused } from "@poscon/shared-frontend";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { sortMenuWidth } from "components/SortMenu";
import { bottomRightBorderColor, font2, font2Dimension, topLeftBorderColor } from "~/utils/constants";

type SortOptionProps = {
  text: string;
  disabled?: boolean;
  indicator?: "diamond" | "rectangle";
  selected: boolean;
  x?: number;
  y: number;
} & Pick<PixiContainer, "onmousedown">;
export const SortMenuItem = ({
  text,
  disabled,
  selected,
  onmousedown,
  y,
  x = 0,
  indicator = "diamond",
}: SortOptionProps) => {
  const ref = useRef<PixiGraphics>(null);
  const focused = useFocused(ref);

  const tint = disabled ? 0x575757 : 0xadadad;

  return (
    <Container x={x} y={y} eventMode={disabled ? "none" : "static"}>
      <Graphics
        zIndex={0}
        ref={ref}
        eventMode="static"
        onmousedown={onmousedown}
        draw={(graphics) => {
          graphics.clear();
          graphics.lineStyle(1, focused ? 0xffffff : 0x000000);
          graphics
            .beginFill(0x000000)
            .drawRect(0, 0, sortMenuWidth - font2Dimension.width * 2, font2Dimension.height + 4)
            .endFill();
        }}
      />
      <Graphics
        y={Math.floor(font2Dimension.height / 2) + 3}
        x={11}
        zIndex={1}
        eventMode="none"
        angle={indicator === "diamond" ? 45 : 0}
        anchor={[0, 0.5]}
        draw={(graphics) => {
          graphics.clear();
          graphics.lineStyle(2, selected ? bottomRightBorderColor : topLeftBorderColor);
          graphics
            .beginFill(selected ? 0xadadad : 0x000000)
            .drawRect(-5, -5, 10, 10)
            .endFill();
          graphics
            .lineStyle(2, selected ? topLeftBorderColor : bottomRightBorderColor)
            .moveTo(5, -5)
            .lineTo(5, 5)
            .lineTo(-5, 5);
        }}
      />
      <BitmapText
        zIndex={1}
        text={text.toUpperCase()}
        x={font2Dimension.width + 12}
        y={3}
        eventMode="none"
        tint={tint}
        style={{ fontName: font2, tint }}
      />
    </Container>
  );
};
