import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type {
  AltimeterListEntry,
  Coordinate,
  DPosConfig,
  FlightplanId,
  GIMessage,
  MsgOutFilterValueMap,
  Nullable,
  SaaId,
} from "@poscon/shared-types";
import { initialMsgOutFilterMap } from "@poscon/shared-types";
import type { WindowPosition, WindowDimension } from "@poscon/shared-frontend";
import type { EdstMenu, EdstView } from "types/edstView";
import { edstMenus, edstViews } from "types/edstView";
import type { Asel } from "types/asel";
import type { RootState, RootThunkAction } from "~redux/store";
import type { OutageEntry } from "~/utils/outageEntry";
import type { Rectangle } from "pixi.js";

export const AIRCRAFT_MENUS: EdstMenu[] = [
  "PLAN_OPTIONS",
  "ALTITUDE_MENU",
  "ROUTE_MENU",
  "PREV_ROUTE_MENU",
  "SPEED_MENU",
  "HEADING_MENU",
  "HOLD_MENU",
  "CANCEL_HOLD_MENU",
  "TEMPLATE_MENU",
  "EQUIPMENT_TEMPLATE_MENU",
];

export const FULLSCREEN_VIEWS: EdstView[] = ["ACL", "DEP", "GPD", "PLANS_DISPLAY", "WIND"];

type GIEntry = GIMessage & {
  acknowledged: boolean;
};

type EdstViewMapValue = {
  open: boolean;
  view: EdstView;
  position: WindowPosition;
  dimension: WindowDimension;
  isFullscreen: boolean;
};

type WindgridState = {
  centerOverride: Coordinate;
  altitude: number;
  scale: number;
  showTemp: boolean;
};

type EdstState = {
  viewStateMap: Record<EdstView, EdstViewMapValue>;
  msgOutFilterMap: MsgOutFilterValueMap;
  giEntryMap: Record<string, GIEntry>;
  asel: Nullable<Asel>;
  zStack: EdstView[];
  outages: OutageEntry[];
  toolbarPosition: "top" | "bottom";
  dPosConfig: DPosConfig;
  showSatCommIndicator: boolean;
  windGrid: WindgridState;
  addSaaAltId: Nullable<SaaId>;
};

export const defaultViewPositions: Partial<Record<EdstView, WindowPosition>> = {
  STATUS: { x: 400, y: 100 },
  OUTAGE: { x: 400, y: 100 },
  MESSAGE_COMPOSE_AREA: { x: 100, y: 400 },
  GPD: { x: 0, y: 38 },
  ACL: { x: 0, y: 38 },
  DEP: { x: 0, y: 38 },
};

const initialViewState = Object.fromEntries(
  edstViews.map((value) => [
    value,
    {
      open: false,
      isFullscreen: FULLSCREEN_VIEWS.includes(value),
      position: defaultViewPositions[value] ?? { x: 100, y: 100 },
      dimension: { width: 0, height: 0 },
    },
  ]),
) as Record<EdstView, EdstViewMapValue>;

const defautlDPosConfig: DPosConfig = {
  aclParams: {
    aircraftOverdue: 0,
    aircraftRemoveOverdue: 0,
    keepPostingPeriod: 0,
    manualPostingPeriod: 0,
    removalCodingPeriod: 0,
    removalCodingDropTrack: 0,
    keepRemovalCodingPeriod: 0,
    periodBeforeBndCrossing: 0,
    coordTimeVariance: 0,
    coordAltitudeDefault: false,
    coordBeaconDefault: false,
    coordRouteDefault: false,
    templateDisplayGlobal: false,
    templateDisplayLanding: false,
    templateLandingLabel: "",
    templateDisplaySpecial: false,
    templateSpecialLabel: "",
    templateDisplayRNP: false,
    periodAutoHandoffBndCrossing: 0,
    periodAutoHandoffRemoval: 0,
  },
  dPosCommonData: {
    tailoringEnabled: false,
    tailoringRouteFixes: 0,
    truncationEnabled: false,
    truncationFixes: 0,
    truncationRouteElements: 0,
  },
  depParams: {
    keepPostingPeriod: 0,
    manualPostingPeriod: 0,
    postingTimeBeforeDepart: 0,
    removalCodingPeriod: 0,
    keepRemovalCodingPeriod: 0,
    dlPostingCriteria: "",
  },
  artccId: "KZNY",
  gpdParams: {
    airwayFilterBoundary: 0,
    saaFilterBoundaryHighUH: 0,
    saaFilterBoundaryLowHigh: 0,
    sectorBoundaryHighUH: 0,
    sectorBoundaryLowHigh: 0,
    sectorBoundaryULLow: 0,
    gpdCenter: [0, 0],
  },
  geoBoundary: {
    type: "Polygon",
    coordinates: [],
  },
  plansDisplayParams: {
    codeConflictNotif: 0,
    codeConflictSeverity: 0,
    codeEndConflict: 0,
    codeNewConflict: 0,
    codeViolation: 0,
    deleteAMAcceptRsp: 0,
    deleteAMRejectRsp: 0,
    deleteFPAcceptRsp: 0,
    deleteFPRejectRsp: 0,
    deleteHSAcceptRsp: 0,
    deleteHSRejectRsp: 0,
    deleteQQAcceptRsp: 0,
    deleteQQRejectRsp: 0,
    deleteQHAcceptRsp: 0,
    deleteQHRejectRsp: 0,
    flightPlanDeleteCoding: 0,
    trialPlanDeleteCoding: 0,
    trialPlanExpiration: 0,
  },
  windGridMapCenter: [0, 0],
};

const initialState: EdstState = {
  addSaaAltId: null,
  asel: null,
  dPosConfig: defautlDPosConfig,
  giEntryMap: {},
  msgOutFilterMap: initialMsgOutFilterMap,
  outages: [],
  showSatCommIndicator: false,
  toolbarPosition: "top",
  viewStateMap: initialViewState,
  windGrid: {
    centerOverride: [0, 0],
    altitude: 200,
    scale: 1,
    showTemp: false,
  },
  zStack: [],
};

export const edstSlice = createSlice({
  name: "edst",
  initialState,
  reducers: {
    setShowSatCommIndicator(state, action: PayloadAction<boolean>) {
      state.showSatCommIndicator = action.payload;
    },
    setToolbarPosition(state, action: PayloadAction<"top" | "bottom">) {
      state.toolbarPosition = action.payload;
    },
    closeView(state, action: PayloadAction<EdstView | EdstView[]>) {
      if (Array.isArray(action.payload)) {
        for (const view of action.payload) {
          state.viewStateMap[view].open = false;
        };
      } else {
        state.viewStateMap[action.payload].open = false;
      }
    },
    openView(state, action: PayloadAction<EdstView>) {
      state.viewStateMap[action.payload].open = true;
      state.zStack = [...state.zStack.filter((view) => view !== action.payload), action.payload];
    },
    setDPosConfig(state, action: PayloadAction<DPosConfig>) {
      state.dPosConfig = action.payload;
      state.windGrid.centerOverride = [0, 0];
      state.windGrid.scale = 1;
    },
    setIsFullscreen(state, action: PayloadAction<{ view: EdstView; value: boolean }>) {
      state.viewStateMap[action.payload.view].isFullscreen = action.payload.value;
    },
    setViewPosition(state, action: PayloadAction<{ view: EdstView; pos: WindowPosition }>) {
      const { x, y } = action.payload.pos;
      state.viewStateMap[action.payload.view].position = { x: Math.round(x), y: Math.round(y) };
    },
    setViewDimension(state, action: PayloadAction<{ view: EdstView; dim: WindowDimension }>) {
      state.viewStateMap[action.payload.view].dimension = action.payload.dim;
    },
    setGIMessages(state, action: PayloadAction<GIMessage[]>) {
      state.giEntryMap = Object.fromEntries(action.payload.map((m) => [m.id, { ...m, acknowledged: false }]));
    },
    setGIEntryAcknowledged(state, action: PayloadAction<string>) {
      state.giEntryMap[action.payload]!.acknowledged = true;
    },
    delGIEntry(state, action: PayloadAction<string>) {
      delete state.giEntryMap[action.payload];
    },
    setAsel(state, action: PayloadAction<Nullable<Asel>>) {
      state.asel = action.payload;
    },
    pushZStack(state, action: PayloadAction<EdstView>) {
      state.zStack = [...state.zStack.filter((view) => view !== action.payload), action.payload];
    },
    addOutageMessage(state, action: PayloadAction<OutageEntry>) {
      state.outages = [...state.outages, action.payload];
    },
    // removes outage message at index
    delOutageMessage(state, action: PayloadAction<string>) {
      state.outages = state.outages.filter((outage) => outage.id !== action.payload);
    },
    setMsgOutFilterValue(
      state,
      action: PayloadAction<{
        category: keyof MsgOutFilterValueMap;
        row: string;
        value: boolean;
      }>,
    ) {
      const { category, row, value } = action.payload;
      if (row in state.msgOutFilterMap[category]) {
        (state.msgOutFilterMap as any)[category][row] = value;
      }
    },
    resetMsgOutFilter(state) {
      state.msgOutFilterMap = { ...initialMsgOutFilterMap };
    },
    translateWindGridCenter(state, action: PayloadAction<Coordinate>) {
      state.windGrid.centerOverride[0] -= state.windGrid.scale * action.payload[0];
      state.windGrid.centerOverride[1] -= state.windGrid.scale * action.payload[1];
    },
    setWindGridAltitude(state, action: PayloadAction<number>) {
      state.windGrid.altitude = action.payload;
    },
    setWindGridShowTemp(state, action: PayloadAction<boolean>) {
      state.windGrid.showTemp = action.payload;
    },
    setWindgridMapScale(state, action: PayloadAction<{ value: number; rect?: Rectangle }>) {
      const { value: newScale, rect } = action.payload;
      if (rect) {
        const oldScale = state.windGrid.scale;
        state.windGrid.centerOverride[0] -= rect.width / 2;
        state.windGrid.centerOverride[1] -= rect.height / 2;
        state.windGrid.centerOverride[0] *= newScale / oldScale;
        state.windGrid.centerOverride[1] *= newScale / oldScale;
        state.windGrid.centerOverride[0] += rect.width / 2;
        state.windGrid.centerOverride[1] += rect.height / 2;
      }
      state.windGrid.scale = newScale;
    },
    setAddSaaAltId(state, action: PayloadAction<Nullable<SaaId>>) {
      state.addSaaAltId = action.payload;
    },
    setEdstState(state, action: PayloadAction<EdstState>) {
      return action.payload;
    },
  },
});

export const {
  setDPosConfig,
  setToolbarPosition,
  setIsFullscreen,
  setViewPosition,
  setViewDimension,
  openView,
  pushZStack,
  setGIMessages,
  setGIEntryAcknowledged,
  delGIEntry,
  addOutageMessage,
  delOutageMessage,
  setMsgOutFilterValue,
  resetMsgOutFilter,
  setShowSatCommIndicator,
  translateWindGridCenter,
  setWindGridAltitude,
  setWindgridMapScale,
  setWindGridShowTemp,
  setAddSaaAltId,
  setEdstState,
  closeView,
} = edstSlice.actions;
export const reducer = edstSlice.reducer;

export const closeAllMenus = (): RootThunkAction => {
  return (dispatch) => {
    dispatch(closeView(edstMenus));
    dispatch(setAsel(null));
  };
};

export const closeAircraftMenus = () => closeView(AIRCRAFT_MENUS);

export function setAsel(asel: Nullable<Asel>): RootThunkAction {
  return (dispatch, getState) => {
    if (asel === null || Object.keys(getState().tracks.flightplans).includes(asel.fpId)) {
      dispatch(closeAircraftMenus());
      dispatch(edstSlice.actions.setAsel(asel));
    }
  };
}

export function closeAselMenu(): RootThunkAction {
  return (dispatch, getState) => {
    const asel = getState().edst.asel;
    if (asel !== null) {
      dispatch(edstSlice.actions.setAsel({ ...asel, menu: undefined }));
    }
  };
}

export const toggleView = (view: EdstView): RootThunkAction => {
  return (dispatch, getState) => {
    const isOpen = getState().edst.viewStateMap[view].open;
    if (isOpen) {
      dispatch(closeView(view));
    } else {
      dispatch(openView(view));
    }
  };
};

export const giEntryMapSelector = (state: RootState) => state.edst.giEntryMap;
export const viewStateMapSelector = (state: RootState) => state.edst.viewStateMap;
export const viewSelector = (state: RootState, view: EdstView) => state.edst.viewStateMap[view];
export const viewPositionSelector = (state: RootState, view: EdstView) => state.edst.viewStateMap[view].position;
export const viewDimensionSelector = (state: RootState, view: EdstView) => state.edst.viewStateMap[view].dimension;
export const viewIsFullscreenSelector = (state: RootState, view: EdstView) =>
  state.edst.viewStateMap[view].isFullscreen;
export const aselSelector = (state: RootState) => state.edst.asel;
export const aclAselSelector = (state: RootState) =>
  state.edst.asel?.field?.endsWith("ACL_ROW_FIELD") ? state.edst.asel : null;
export const depAselSelector = (state: RootState) =>
  state.edst.asel?.field?.endsWith("DEP_ROW_FIELD") ? state.edst.asel : null;
export const gpdAselSelector = (state: RootState) =>
  state.edst.asel?.field?.endsWith("DB_FIELD") ? state.edst.asel : null;
export const zStackSelector = (state: RootState) => state.edst.zStack;
export const outageSelector = (state: RootState) => state.edst.outages;
export const viewsSelector = (state: RootState) => state.edst.viewStateMap;
export const toolbarPositionSelector = (state: RootState) => state.edst.toolbarPosition;
export const aircraftIsAselSelector = (state: RootState, fpId: FlightplanId) =>
  state.edst.asel?.fpId === fpId ? state.edst.asel : null;
export const msgOutFilterValuesSelector = (state: RootState) => state.edst.msgOutFilterMap;
export const showSatCommIndicatorSelector = (state: RootState) => state.edst.showSatCommIndicator;
export const windgridStateSelector = (state: RootState) => state.edst.windGrid;
export const dposConfigSelector = (state: RootState) => state.edst.dPosConfig;
export const addSaaAltIdSelector = (state: RootState) => state.edst.addSaaAltId;

export const setWindgridScaleToRange = (range: number, prevRange: number, rect: Rectangle): RootThunkAction => {
  return (dispatch, getState) => {
    const { scale } = getState().edst.windGrid;
    dispatch(
      setWindgridMapScale({
        value: (scale * prevRange) / Math.max(range, 1),
        rect,
      }),
    );
  };
};
