import React from "react";
import { View } from "components/utils/View";
import { brightOption, fontOption, viewOptionSelector } from "~redux/slices/viewOptionSlice";
import { ViewOptionContextProvider } from "contexts/viewOptionContext";
import { useRootSelector } from "~redux/hooks";

const view = "STATUS";

const optionMap = {
  font: fontOption(view),
  bright: brightOption(view),
};

export const Status = () => {
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View width={20} height={0} view={view} optionMap={optionMap} />
    </ViewOptionContextProvider>
  );
};
