import React, { useRef } from "react";
import type { FederatedEventHandler, Graphics as PixiGraphics } from "pixi.js";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { bottomRightBorderColor, font2, font2Dimension, topLeftBorderColor } from "~/utils/constants";
import { useFocused } from "@poscon/shared-frontend";

const fontName = font2;

type EdstButtonProps = {
  x: number;
  y?: number;
  onmousedown?: FederatedEventHandler;
  disabled?: boolean;
  text: string;
  width?: number;
};
export function EdstButton({
  onmousedown,
  disabled,
  text,
  x,
  y = 0,
  width = text.length * font2Dimension.width + 10,
}: EdstButtonProps) {
  const ref = useRef<PixiGraphics>(null);
  const focused = useFocused(ref);

  const tint = disabled ? 0x707070 : 0xadadad;

  return (
    <Container x={x} y={y}>
      <Graphics
        ref={ref}
        draw={(graphics) => {
          graphics.clear();
          if (focused) {
            graphics.lineStyle(1, 0xffffff, 1, 1);
            graphics.drawRect(0, 0, width, font2Dimension.height + 4);
          }
          graphics.lineStyle(2, topLeftBorderColor, 1, 0);
          graphics.beginFill(0x000000);
          graphics.drawRect(0, 0, width, font2Dimension.height + 4);
          graphics.endFill();
          graphics
            .lineStyle(2, bottomRightBorderColor, 1, 0)
            .moveTo(width, 0)
            .lineTo(width, font2Dimension.height + 4)
            .lineTo(0, font2Dimension.height + 4);
        }}
        eventMode={disabled ? "none" : "static"}
        onmousedown={onmousedown}
      />
      <BitmapText
        text={text.toUpperCase()}
        x={Math.floor((width - text.length * font2Dimension.width) / 2)}
        y={3}
        eventMode="none"
        tint={tint}
        style={{
          fontName,
          tint,
        }}
      />
    </Container>
  );
}
