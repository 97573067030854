import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { closeView, viewSelector } from "~redux/slices/edstSlice";
import { useDragging } from "hooks/useDragging";
import { eramTextDimensionMap, useCenterCursor } from "@poscon/shared-frontend";
import type { Container as PixiContainer } from "pixi.js";
import { Container, Graphics } from "@pixi/react";
import { useZIndex } from "hooks/useZIndex";
import { MenuTitleBar } from "components/utils/MenuTitleBar";
import { font2Dimension } from "~/utils/constants";
import { EdstButton } from "components/utils/EdstButton";

const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[2];

export const sortMenuWidth = 22 * fontWidth;

type SortMenuProps = {
  menu: "ACL_SORT_MENU" | "DEP_SORT_MENU";
  onSubmit: () => void;
  children: React.ReactNode;
  height: number;
};

export const SortMenu = ({ menu, onSubmit, children, height }: SortMenuProps) => {
  const dispatch = useRootDispatch();
  const viewProps = useRootSelector((state) => viewSelector(state, menu));

  const ref = useRef<PixiContainer>(null);
  const zIndex = useZIndex(menu, ref);
  // const focused = useFocused(ref);
  useCenterCursor(ref);
  const { startDrag } = useDragging(ref, menu, "mouseup");

  return (
    <Container
      ref={ref}
      x={viewProps.position.x}
      y={viewProps.position.y}
      zIndex={zIndex}
      eventMode="static"
      sortableChildren
    >
      <MenuTitleBar title="SORT MENU" width={sortMenuWidth} onTitleMouseDown={startDrag} view={menu} />
      <Container y={fontHeight + 2}>
        <Graphics
          draw={(graphics) => {
            graphics.clear();
            graphics.lineStyle(1, 0xadadad);
            graphics
              .beginFill(0x000000)
              .drawRect(0, 0, sortMenuWidth, height + font2Dimension.height + 12)
              .endFill();
          }}
        />
        <Container y={4}>{children}</Container>
        <Container y={height + 4}>
          <EdstButton x={4} text="OK" onmousedown={onSubmit} />
          <EdstButton
            x={sortMenuWidth - font2Dimension.width * 4 - 15}
            text="EXIT"
            onmousedown={() => dispatch(closeView(menu))}
          />
        </Container>
      </Container>
    </Container>
  );
};
