import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { setWindGridAltitude, viewSelector, windgridStateSelector } from "~redux/slices/edstSlice";
import { useDragging } from "hooks/useDragging";
import { colorNameMap, eramTextDimensionMap, MenuElement, useCenterCursor } from "@poscon/shared-frontend";
import type { Container as PixiContainer } from "pixi.js";
import { useZIndex } from "hooks/useZIndex";
import { Container, Graphics } from "@pixi/react";
import { MenuTitleBar } from "components/utils/MenuTitleBar";
import { range } from "lodash";

const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[2];

const width = 16 * fontWidth;
const height = 30 * (fontHeight + 6) + 6;

const view = "WIND_ALT_MENU";

const itemWidth = fontWidth * 3 + 4;

export const WindAltMenu = () => {
  const dispatch = useRootDispatch();
  const viewProps = useRootSelector((state) => viewSelector(state, view));
  const windgridParams = useRootSelector(windgridStateSelector);
  const ref = useRef<PixiContainer>(null);
  const zIndex = useZIndex(view, ref);
  useCenterCursor(ref);
  const { startDrag } = useDragging(ref, view, "mouseup");

  return (
    <Container
      ref={ref}
      x={viewProps.position.x}
      y={viewProps.position.y}
      zIndex={zIndex}
      eventMode="static"
      sortableChildren
    >
      <MenuTitleBar title="WIND ALT MENU" width={width} onTitleMouseDown={startDrag} view={view} />
      <Container y={fontHeight + 2} sortableChildren>
        <Graphics
          draw={(graphics) => {
            graphics.clear();
            graphics.lineStyle(1, 0xadadad);
            graphics.beginFill(0x000000).drawRect(0, 0, width, height).endFill();
          }}
        />
        {range(0, 59).map((i) => {
          const v = (i + 1) * 10;
          const selected = windgridParams.altitude === v;
          return (
            <MenuElement
              key={i}
              x={i < 30 ? 20 : width - itemWidth - 20}
              y={4 + (fontHeight + 6) * (i % 30)}
              text={v.toString().padStart(3, "0")}
              width={itemWidth}
              borderColor={0x000000}
              selected={selected}
              selectReverseVideo
              tint={colorNameMap.lightGrey}
              onmousedown={() => {
                dispatch(setWindGridAltitude(v));
              }}
            />
          );
        })}
      </Container>
    </Container>
  );
};
