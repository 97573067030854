import React, { useRef } from "react";
import type { ColorSource, FederatedEventHandler, Graphics as PixiGraphics } from "pixi.js";
import { eramFontDimensionMap } from "@poscon/shared-frontend";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { font2 } from "~/utils/constants";
import { Rectangle } from "pixi.js";

const fontName = font2;
const fontDimension = eramFontDimensionMap[fontName];

export const titleBarHeight = fontDimension.height + 6;

function drawBlock(
  graphics: PixiGraphics,
  fillColor: ColorSource,
  topLeftBlockColor: ColorSource,
  bottomRightBlockColor: ColorSource,
  width = titleBarHeight - 2,
  height = titleBarHeight - 2,
  x = 0,
  y = 0,
) {
  graphics.lineStyle(1, topLeftBlockColor).beginFill(fillColor).drawRect(x, y, width, height).endFill();
  graphics
    .lineStyle(1, bottomRightBlockColor)
    .moveTo(x + width, y)
    .lineTo(x + width, y + height)
    .lineTo(x, y + height);
}

type WindowTitleBarProps = {
  focused: boolean;
  closeView: () => void;
  toggleFullscreen: () => void;
  startDrag: FederatedEventHandler;
  text: string[];
  width: number;
};

/**
 * Window title bar for various components
 */
export const ViewTitleBar = ({
  focused,
  text,
  closeView,
  toggleFullscreen,
  startDrag,
  width,
}: WindowTitleBarProps) => {
  const leftRef = useRef<PixiGraphics>(null);
  const toggleFullscreenRef = useRef<PixiGraphics>(null);
  const closeRef = useRef<PixiGraphics>(null);

  const fillColor = focused ? 0x008585 : 0x888888;
  const topLeftBlockColor = 0xadadad;
  const bottomRightBlockColor = focused ? 0x005757 : 0x575757;

  const headerText = text.map((s) => s.toUpperCase()).join("  ");

  return (
    <Container x={2} y={2} eventMode="static">
      <Graphics
        ref={leftRef}
        draw={(graphics) => {
          graphics.clear();
          drawBlock(graphics, fillColor, topLeftBlockColor, bottomRightBlockColor);
          drawBlock(
            graphics,
            fillColor,
            topLeftBlockColor,
            bottomRightBlockColor,
            9,
            3,
            Math.floor((titleBarHeight - 2) / 2) - 4,
            Math.floor((titleBarHeight - 2) / 2) - 1,
          );
        }}
        eventMode="static"
        onmousedown={toggleFullscreen}
      />
      <Graphics
        x={titleBarHeight - 2 + 1}
        draw={(graphics) => {
          graphics.clear();
          drawBlock(graphics, fillColor, topLeftBlockColor, bottomRightBlockColor, width - titleBarHeight * 3 + 1);
        }}
        eventMode="static"
        onmousedown={startDrag}
      />
      <BitmapText
        text={headerText}
        fontName={fontName}
        eventMode="none"
        x={Math.floor((width - headerText.length * fontDimension.width) / 2)}
        y={Math.floor((titleBarHeight - fontDimension.height) / 2)}
        style={{ fontName, tint: 0x000000 }}
      />
      <Graphics
        x={width - titleBarHeight * 2 + 1}
        ref={closeRef}
        draw={(graphics) => {
          graphics.clear();
          drawBlock(graphics, fillColor, topLeftBlockColor, bottomRightBlockColor);
          drawBlock(
            graphics,
            fillColor,
            topLeftBlockColor,
            bottomRightBlockColor,
            3,
            3,
            Math.floor((titleBarHeight - 2) / 2) - 1,
            Math.floor((titleBarHeight - 2) / 2) - 1,
          );
        }}
        hitArea={new Rectangle(0, 0, titleBarHeight, titleBarHeight)}
        eventMode="static"
        onmousedown={closeView}
      />
      <Graphics
        x={width - titleBarHeight}
        ref={toggleFullscreenRef}
        draw={(graphics) => {
          graphics.clear();
          drawBlock(graphics, fillColor, topLeftBlockColor, bottomRightBlockColor);
          drawBlock(
            graphics,
            fillColor,
            topLeftBlockColor,
            bottomRightBlockColor,
            9,
            9,
            Math.floor((titleBarHeight - 8) / 2) - 1,
            Math.floor((titleBarHeight - 8) / 2) - 1,
          );
        }}
        hitArea={new Rectangle(0, 0, titleBarHeight, titleBarHeight)}
        eventMode="static"
        onmousedown={toggleFullscreen}
      />
    </Container>
  );
};
