import React from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { trialPlanCleanup, trialPlanQueueSelector } from "~redux/slices/trailPlanSlice";
import { aselSelector, closeView } from "~redux/slices/edstSlice";
import { EdstButton } from "components/utils/EdstButton";
import { ViewTitleBar } from "components/ViewTitleBar";
import type { HeaderComponentProps } from "components/utils/FullscreenView";
import { openViewThunk } from "~redux/thunks/openViewThunk";
import type { DisplayObject } from "pixi.js";
import { Container } from "@pixi/react";
import { font2Dimension } from "~/utils/constants";
import { eramHubConnection } from "~/eramHubConnection";

/**
 * Plans Display title bar and header row with add/find input field
 */
export const PlansDisplayHeader = ({ focused, toggleFullscreen, startDrag, width }: HeaderComponentProps) => {
  const dispatch = useRootDispatch();
  const planQueue = useRootSelector(trialPlanQueueSelector);
  const asel = useRootSelector(aselSelector);
  const interimDisabled = true;

  const handleAmendClick = () => {
    if (asel?.field === "FID_PLAN_ROW_FIELD") {
      // void amendFlightplan(amendedFlightplan);
    }
  };

  return (
    <Container>
      <ViewTitleBar
        focused={focused}
        width={width}
        toggleFullscreen={toggleFullscreen}
        startDrag={startDrag}
        closeView={() => dispatch(closeView("PLANS_DISPLAY"))}
        text={["Plans Display"]}
      />
      <EdstButton
        x={4}
        onmousedown={(e) => {
          dispatch(openViewThunk("PLAN_OPTIONS", e.target as DisplayObject));
        }}
        disabled={asel === null}
        text="Plan Options..."
      />
      <EdstButton x={4 + font2Dimension.width * 15 + 8 * 2} disabled text="Show" />
      <EdstButton x={4 + font2Dimension.width * 19 + 8 * 4} disabled text="Show ALL" />
      <EdstButton x={4 + font2Dimension.width * 27 + 8 * 6} disabled text="AM+Uplink" />
      <EdstButton
        x={4 + font2Dimension.width * 36 + 8 * 8}
        disabled={asel === null}
        text="Amend"
        onmousedown={handleAmendClick}
      />
      <EdstButton
        x={4 + font2Dimension.width * 41 + 8 * 10}
        disabled={interimDisabled}
        text="Interim"
        onmousedown={() => {
          if (asel?.field === "FID_PLAN_ROW_FIELD") {
            /* TODO: implement */
          }
        }}
        //
      />
      <EdstButton
        x={4 + font2Dimension.width * 48 + 8 * 12}
        text="Tools..."
        onmousedown={(e) => {
          dispatch(openViewThunk("TOOLS_MENU", e.target as DisplayObject));
        }}
      />
      <EdstButton
        x={4 + font2Dimension.width * 56 + 8 * 14}
        text="Template..."
        onmousedown={(e) => {
          dispatch(openViewThunk("TEMPLATE_MENU", e.target as DisplayObject));
        }}
      />
      <EdstButton x={4 + font2Dimension.width * 67 + 8 * 16} disabled text="ICAO" />
      <EdstButton
        x={4 + font2Dimension.width * 71 + 8 * 18}
        onmousedown={() => {
          eramHubConnection.emit("trialPlanCleanup");
          dispatch(closeView("PLANS_DISPLAY"));
        }}
        text="Clean Up"
      />
    </Container>
  );
};
