import { createListenerMiddleware } from "@reduxjs/toolkit";
import type { RootDispatch, RootState } from "./store";
import { setMcaFeedback } from "@poscon/shared-frontend";
import { openView, pushZStack } from "./slices/edstSlice";

export const listenerMiddleware = createListenerMiddleware();
export const startListening = listenerMiddleware.startListening.withTypes<RootState, RootDispatch>();

startListening({
  predicate: (action) => setMcaFeedback.match(action),
  effect: (action, { dispatch }) => {
    dispatch(openView("MESSAGE_COMPOSE_AREA"));
    dispatch(pushZStack("MESSAGE_COMPOSE_AREA"));
  },
});