import type { EdstView } from "types/edstView";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { setIsFullscreen, viewIsFullscreenSelector } from "~redux/slices/edstSlice";

export const useFullscreen = (view: EdstView) => {
  const dispatch = useRootDispatch();
  const isFullscreen = useRootSelector((state) => viewIsFullscreenSelector(state, view));

  const toggleFullscreen = () => {
    dispatch(setIsFullscreen({ view, value: !isFullscreen }));
  };

  return { isFullscreen, toggleFullscreen };
};
