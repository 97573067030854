import type { ComponentType } from "react";
import React, { useRef } from "react";
import { EdstToolbar } from "components/EdstToolbar";
import { Acl } from "components/Acl";
import { Dep } from "components/Dep";
import { Status } from "components/Status";
import { Outage } from "components/Outage";
import { PlanOptions } from "components/PlanOptions";
import { PlansDisplay } from "components/PlansDisplay";
import { MessageComposeArea } from "components/MessageComposeArea";
import { MessageResponseArea } from "components/MessageResponseArea";
import { TemplateMenu } from "components/TemplateMenu";
import { addSaaAltIdSelector, aselSelector, viewsSelector } from "~redux/slices/edstSlice";
import { useRootSelector } from "~redux/hooks";
import { ToolsMenu } from "components/tools/ToolsMenu";
import { AltimSet } from "components/views/AltimSet";
import { WxReport } from "components/views/WxReport";
import { EquipmentTemplateMenu } from "components/template/EquipmentTemplateMenu";
import { SigmetView } from "components/SigmetView";
import { Gpd } from "components/Gpd";
import { GpdMapOptions } from "components/gpd/GpdMapOptions";
import type { EdstAselMenu } from "types/edstView";
import { GI } from "components/GIView";
import { AclSortMenu } from "components/acl/AclSortMenu";
import { DepSortMenu } from "components/dep/DepSortMenu";
import { CpdlcAdv } from "components/CpdlcAdv";
import { CpdlcHistory } from "components/CpdlcHistory";
import { CpdlcMsgOut } from "components/CpdlcMsgOut";
import type { FlightplanId } from "@poscon/shared-types";
import { unsafeEntries } from "@poscon/shared-types";
import type { Graphics as PixiGraphics } from "pixi.js";
import { Container, Graphics } from "@pixi/react";
import { DragContextProvider } from "contexts/dragContext";
import { CursorContextProvider } from "contexts/cursorContext";
import {
  advCreationTemplateView,
  advDeleteView,
  advFreetextTemplateView,
  advUplinkTemplateView,
  cpdlcMiniMOView,
  eligibilityPromptSelector,
  InopX,
  InputContextProvider,
  LockedUiBorder,
  selectedAdvViewSelector,
  useAutoSignin,
} from "@poscon/shared-frontend";
import { WindGridDisplay } from "components/WindGridDisplay";
import { WindAltMenu } from "components/wind/WindAltMenu";
import {
  AltitudeMenu,
  HeadingMenu,
  HoldMenu,
  RouteMenu,
  SpeedMenu,
  XResMenu,
} from "components/menus/AircraftMenu";
import { AirspaceStatus } from "components/AirspaceStatus";
import { EligibilityOverridePrompt } from "components/prompts/EligibilityOverridePrompt";
import { CpdlcAdvCreationTemplate } from "components/CpdlcAdvCreationTemplate";
import { CpdlcAdvUplinkTemplate } from "components/CpdlcAdvUplinkTemplate";
import { CpdlcAdvDeletePrompt } from "components/CpdlcAdvDeletePrompt";
import { CpdlcAdvFreetextTemplate } from "components/CpdlcAdvFreetextTemplate";
import { AddAirspaceAltitude } from "components/prompts/AddAirspaceAltitude";
import { CCommonContextProvider } from "contexts/commonContext";
import { initializeConnection } from "./eramHubConnection";
import { InteractiveContainer } from "components/utils/InteractiveContainer";
import { PoMenu } from "components/prompts/PoMenu";

const edstComponentMap = {
  ACL: Acl,
  ACL_SORT_MENU: AclSortMenu,
  AIRSPACE_STATUS: AirspaceStatus,
  ALTIM_SET: AltimSet,
  CPDLC_ADV: CpdlcAdv,
  CPDLC_HIST: CpdlcHistory,
  CPDLC_MSGOUT: CpdlcMsgOut,
  DEP: Dep,
  DEP_SORT_MENU: DepSortMenu,
  EQUIPMENT_TEMPLATE_MENU: EquipmentTemplateMenu,
  GI,
  GPD: Gpd,
  GPD_MAP_OPTIONS_MENU: GpdMapOptions,
  MESSAGE_RESPONSE_AREA: MessageResponseArea,
  OUTAGE: Outage,
  PLANS_DISPLAY: PlansDisplay,
  SIGMETS: SigmetView,
  STATUS: Status,
  TEMPLATE_MENU: TemplateMenu,
  TOOLS_MENU: ToolsMenu,
  WIND: WindGridDisplay,
  WIND_ALT_MENU: WindAltMenu,
  WX_REPORT: WxReport,
} as const;

const cpdlcAdvViewMap = {
  [advCreationTemplateView]: CpdlcAdvCreationTemplate,
  [advUplinkTemplateView]: CpdlcAdvUplinkTemplate,
  [advDeleteView]: CpdlcAdvDeletePrompt,
  [advFreetextTemplateView]: CpdlcAdvFreetextTemplate,
};

const flightplanMenuMap: Record<EdstAselMenu, ComponentType<{ fpId: FlightplanId }> | null> = {
  ALTITUDE_MENU: AltitudeMenu,
  CANCEL_HOLD_MENU: null,
  CHANGE_DEST_MENU: null,
  HEADING_MENU: HeadingMenu,
  HOLD_MENU: HoldMenu,
  [cpdlcMiniMOView]: null,
  PLAN_OPTIONS: PlanOptions,
  PO_MENU: PoMenu,
  PREV_ROUTE_MENU: null,
  FREETEXT_MENU: null,
  ROUTE_MENU: RouteMenu,
  SPEED_MENU: SpeedMenu,
  X_RES_MENU: XResMenu,
} as const;

export const Edst = () => {
  useAutoSignin(initializeConnection);
  const draggingOutlineRef = useRef<PixiGraphics>(null);
  const views = useRootSelector(viewsSelector);
  const asel = useRootSelector(aselSelector);
  const eligibilityPrompt = useRootSelector(eligibilityPromptSelector);
  const selectedAdvView = useRootSelector(selectedAdvViewSelector);
  const addSaaAltId = useRootSelector(addSaaAltIdSelector);

  const AselComponent = asel?.menu ? flightplanMenuMap[asel.menu] : null;
  const CpdlcAdvComponent = selectedAdvView ? cpdlcAdvViewMap[selectedAdvView.option] : null;

  return (
    <>
      <InopX />
      <Graphics name="DRAGGING_OUTLINE" ref={draggingOutlineRef} zIndex={2} eventMode="none" />
      <CCommonContextProvider>
        <DragContextProvider draggingOutlineRef={draggingOutlineRef}>
          <CursorContextProvider>
            <InputContextProvider>
              <InteractiveContainer zIndex={1} sortableChildren>
                <LockedUiBorder />
                <Container zIndex={2} eventMode="static">
                  <EdstToolbar />
                </Container>
                <Container zIndex={1} sortableChildren eventMode="static">
                  <MessageComposeArea />
                  {eligibilityPrompt && (
                    <EligibilityOverridePrompt
                      key={eligibilityPrompt.callsign + eligibilityPrompt.title}
                      prompt={eligibilityPrompt}
                    />
                  )}
                  {CpdlcAdvComponent && <CpdlcAdvComponent key={selectedAdvView?.name} />}
                  {unsafeEntries(edstComponentMap).map(
                    ([edstView, Component]) => views[edstView].open && <Component key={edstView} />,
                  )}
                  {addSaaAltId && <AddAirspaceAltitude key={addSaaAltId} saaId={addSaaAltId} />}
                  {AselComponent && asel && <AselComponent key={asel.fpId} fpId={asel.fpId} />}
                </Container>
              </InteractiveContainer>
            </InputContextProvider>
          </CursorContextProvider>
        </DragContextProvider>
      </CCommonContextProvider>
    </>
  );
};
