import React, { useRef } from "react";
import type { EligibilityPrompt } from "@poscon/shared-types";
import {
  EligibilityOverridePrompt as BaseEligibilityOverridePrompt,
  eligibilityOverrideView,
  setEligibilityPrompt,
} from "@poscon/shared-frontend";
import type { Container as PixiContainer } from "pixi.js";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import { useDragging } from "hooks/useDragging";
import { setViewPosition, viewPositionSelector } from "~redux/slices/edstSlice";
import { layerZIndexMap } from "~/layerZIndexMap";

const view = eligibilityOverrideView;

type EligibilityOverridePromptProps = {
  prompt: EligibilityPrompt;
};

export const EligibilityOverridePrompt = ({ prompt }: EligibilityOverridePromptProps) => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiContainer | null>(null);
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const { startDrag, anyDragging } = useDragging(ref, view);

  return (
    <BaseEligibilityOverridePrompt
      ref={ref}
      pos={pos}
      startDrag={startDrag}
      prompt={prompt}
      closePrompt={() => dispatch(setEligibilityPrompt(null))}
      dispatchCommand={(command) => dispatch(processEramMessage(command))}
      setViewPosition={(pos) => dispatch(setViewPosition({ view, pos }))}
      eventMode={anyDragging ? "none" : "static"}
      zIndex={layerZIndexMap.prompts}
    />
  );
};
