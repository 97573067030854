import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { Nullable, Coordinate } from "@poscon/shared-types";
import type { RootState, RootThunkAction } from "~redux/store";
import type { Rectangle } from "pixi.js";

export enum SectorType {
  ultraLow = "UL",
  low = "L",
  high = "H",
  ultraHigh = "UH",
  lowHigh = "LH",
}

export enum MapFeatureOption {
  ultraLowSectors = "Ultra Low",
  lowSectors = "Low",
  highSectors = "High",
  ultraHighSectors = "Ultra High",
  centerBoundaries = "Center Boundaries",
  approachBoundaries = "Approach Control Boundaries",
  airport = "Airport",
  airportLabels = "Airport Labels",
  Jairways = "J Airways",
  Qairways = "Q Airways",
  Vairways = "V Airways",
  Tairways = "T Airways",
  navaid = "NAVAIDS",
  navaidLabels = "NAVAID Labels",
  waypoint = "Waypoints",
  waypointLabels = "Waypoint Labels",
}

export type MapFeatureOptions = Partial<Record<MapFeatureOption, boolean>>;

export type AircraftDisplayOptions = {
  aircraftListFilter: ["Aircraft List Filter", boolean];
  altitudeFilterLimits: ["Altitude Filter Limits", boolean];
  filterAbove: ["Filter Above", Nullable<number>];
  filterBelow: ["Filter Below", Nullable<number>];
  autoDatablockOffset: ["Auto Datablock Offset", boolean];
  mspLabels: ["MSP/MEP Labels", boolean];
  routePreviewMinutes: ["Route Preview (minutes)", number];
};

type GpdConfiguration = Nullable<Record<string, unknown>>;

export type GpdState = {
  gpdConfiguration: GpdConfiguration;
  mapFeatureOptions: MapFeatureOptions;
  aircraftDisplayOptions: AircraftDisplayOptions;
  centerOverride: Coordinate;
  scale: number;
  suppressed: boolean;
  planData: Record<string, unknown>[];
};

const initialMapFeatureOptionsState = {
  [MapFeatureOption.lowSectors]: true,
  [MapFeatureOption.highSectors]: true,
};

const initialState: GpdState = {
  mapFeatureOptions: initialMapFeatureOptionsState,
  gpdConfiguration: null,
  aircraftDisplayOptions: {
    aircraftListFilter: ["Aircraft List Filter", false],
    altitudeFilterLimits: ["Altitude Filter Limits", false],
    filterAbove: ["Filter Above", null],
    filterBelow: ["Filter Below", null],
    autoDatablockOffset: ["Auto Datablock Offset", false],
    mspLabels: ["MSP/MEP Labels", false],
    routePreviewMinutes: ["Route Preview (minutes)", 0],
  },
  centerOverride: [0, 0],
  scale: 1,
  suppressed: false,
  planData: [],
};

export const gpdSlice = createSlice({
  name: "gpd",
  initialState,
  reducers: {
    setGpdState(state, action: PayloadAction<GpdState>) {
      return action.payload;
    },
    addGpdPlanData(state, action: PayloadAction<Record<string, unknown>>) {
      state.planData.push(action.payload);
    },
    removeGpdPlanData(state, action: PayloadAction<number>) {
      if (action.payload < state.planData.length - 1 && action.payload >= 0) {
        state.planData.splice(action.payload);
      }
    },
    setGpdMapFeatureOptions(state, action: PayloadAction<MapFeatureOptions>) {
      state.mapFeatureOptions = action.payload;
    },
    setGpdAircraftDisplayOptions(state, action: PayloadAction<AircraftDisplayOptions>) {
      state.aircraftDisplayOptions = action.payload;
    },
    setGpdSuppressed(state, action: PayloadAction<boolean>) {
      state.suppressed = action.payload;
    },
    toggleGpdSuppressed(state) {
      state.suppressed = !state.suppressed;
    },
    setGpdCenterOverride(state, action: PayloadAction<Coordinate>) {
      state.centerOverride = action.payload;
    },
    setGpdScale(state, action: PayloadAction<{ value: number; rect?: Rectangle }>) {
      const { value: newScale, rect } = action.payload;
      if (rect) {
        const oldScale = state.scale;
        state.centerOverride[0] -= rect.width / 2;
        state.centerOverride[1] -= rect.height / 2;
        state.centerOverride[0] *= newScale / oldScale;
        state.centerOverride[1] *= newScale / oldScale;
        state.centerOverride[0] += rect.width / 2;
        state.centerOverride[1] += rect.height / 2;
      }
      state.scale = newScale;
    },
  },
});

export const {
  setGpdCenterOverride,
  addGpdPlanData,
  removeGpdPlanData,
  setGpdMapFeatureOptions,
  setGpdAircraftDisplayOptions,
  setGpdSuppressed,
  toggleGpdSuppressed,
  setGpdScale,
  setGpdState,
} = gpdSlice.actions;
export const reducer = gpdSlice.reducer;

export const gpdMapFeatureOptionsSelector = (state: RootState) => state.gpd.mapFeatureOptions;
export const gpdSuppressedSelector = (state: RootState) => state.gpd.suppressed;
export const gpdAircraftDisplayOptionsSelector = (state: RootState) => state.gpd.aircraftDisplayOptions;
export const gpdPlanDataSelector = (state: RootState) => state.gpd.planData;
export const gpdCenterOverrideSelector = (state: RootState) => state.gpd.centerOverride;
export const gpdScaleSelector = (state: RootState) => state.gpd.scale;

export const setGpdScaleToRange = (range: number, prevRange: number, rect: Rectangle): RootThunkAction => {
  return (dispatch, getState) => {
    const scale = gpdScaleSelector(getState());
    dispatch(
      setGpdScale({
        value: (scale * prevRange) / Math.max(range, 1),
        rect,
      }),
    );
  };
};
