import React, { useState } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { depSortOptionSelector, setDepSort } from "~redux/slices/depSlice";
import { SortMenu } from "components/SortMenu";
import type { DepSortMethod } from "@poscon/shared-types";
import { DepSortMethodValues } from "@poscon/shared-types";
import { font2Dimension } from "~/utils/constants";
import { SortMenuItem } from "components/utils/SortMenuItem";
import { Container } from "@pixi/react";

const sortOptionList: { option: DepSortMethod; text: string; disabled: boolean }[] = [
  { option: "DEP_ACID_SORT_METHOD", text: DepSortMethodValues.DEP_ACID_SORT_METHOD, disabled: false },
  {
    option: "DEP_DESTINATION_SORT_METHOD",
    text: DepSortMethodValues.DEP_DESTINATION_SORT_METHOD,
    disabled: false,
  },
  { option: "DEP_ORIGIN_SORT_METHOD", text: DepSortMethodValues.DEP_ORIGIN_SORT_METHOD, disabled: false },
  { option: "DEP_P_TIME_SORT_METHOD", text: DepSortMethodValues.DEP_P_TIME_SORT_METHOD, disabled: false },
];

export const DepSortMenu = () => {
  const dispatch = useRootDispatch();
  const depSelectedSortOption = useRootSelector(depSortOptionSelector);
  const [selectedOption, setSelectedOption] = useState(depSelectedSortOption);

  const onSubmit = () => {
    dispatch(setDepSort(selectedOption));
  };

  return (
    <SortMenu menu="DEP_SORT_MENU" onSubmit={onSubmit} height={font2Dimension.height * 7}>
      <Container x={font2Dimension.width}>
        {sortOptionList.map(({ option, text, disabled }, i) => (
          <SortMenuItem
            key={option}
            text={text}
            disabled={disabled}
            selected={selectedOption === option}
            onmousedown={() => setSelectedOption(option)}
            y={i * (font2Dimension.height + 7)}
          />
        ))}
      </Container>
    </SortMenu>
  );
};
