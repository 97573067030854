import React, { useRef, useState } from "react";

import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { viewSelector, closeView } from "~redux/slices/edstSlice";
import { useDragging } from "hooks/useDragging";
import { useFocused } from "@poscon/shared-frontend";
import { GpdMapFeaturesMenu } from "components/views/gpd/GpdMapFeaturesMenu";
import { useZIndex } from "hooks/useZIndex";

export const GpdMapOptions = () => {
  const dispatch = useRootDispatch();
  const windowProps = useRootSelector((state) => viewSelector(state, "GPD_MAP_OPTIONS_MENU"));
  const [mapFeaturesMenuOpen, setMapFeaturesMenuOpen] = useState(false);
  const [aircraftDisplayMenuIsOpen] = useState(false);
  const ref = useRef(null);
  const zIndex = useZIndex("GPD_MAP_OPTIONS_MENU", ref);
  const focused = useFocused(ref);
  const { startDrag, anyDragging } = useDragging(ref, "GPD_MAP_OPTIONS_MENU", "mouseup");

  return null;
  // return (
  //   <div
  //     className={clsx(optionStyles.root, { noPointerEvents: anyDragging })}
  //     style={{ ...windowProps.position, zIndex }}
  //     ref={ref}
  //   >
  //     <div className={clsx(optionStyles.header, { focused })} onmousedown={startDrag}>
  //       {aircraftDisplayMenuIsOpen && "Aircraft Display"}
  //       {mapFeaturesMenuOpen && "Map Features"}
  //       {!(aircraftDisplayMenuIsOpen || mapFeaturesMenuOpen) && "Map Options"} Menu
  //     </div>
  //     <div className={optionStyles.body}>
  //       {mapFeaturesMenuOpen && <GpdMapFeaturesMenu />}
  //       {!(mapFeaturesMenuOpen || aircraftDisplayMenuIsOpen) && (
  //         <>
  //           <div className={optionStyles.row}>
  //             <div className={clsx(optionStyles.col, "flex")} onmousedown={() => setMapFeaturesMenuOpen(true)}>
  //               Map Features...
  //             </div>
  //           </div>
  //           <div className={optionStyles.row}>
  //             <div className={clsx(optionStyles.col, "flex", { isDisabled: true })}>AC Display Menu...</div>
  //           </div>
  //           <div className={optionStyles.bottomRow}>
  //             <div className={clsx(optionStyles.col, "right")}>
  //               <ExitButton onmousedown={() => dispatch(closeView("GPD_MAP_OPTIONS_MENU"))} />
  //             </div>
  //           </div>
  //         </>
  //       )}
  //     </div>
  //   </div>
  // );
};
