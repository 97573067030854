import { setViewOptionState } from "~redux/slices/viewOptionSlice";
import { setEdstState } from "~redux/slices/edstSlice";
import { setAclState } from "~redux/slices/aclSlice";
import { setDepState } from "~redux/slices/depSlice";
import { setGpdState } from "~redux/slices/gpdSlice";
import { setIsProcessingCommand } from "@poscon/shared-frontend";

export const whitelistedActions: string[] = [
  setEdstState.type,
  setAclState.type,
  setDepState.type,
  setGpdState.type,
  setViewOptionState.type,
  setIsProcessingCommand.type,
];
