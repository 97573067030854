import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { useDragging } from "hooks/useDragging";
import type { FlightplanId } from "@poscon/shared-types";
import type { Container as PixiContainer } from "pixi.js";
import { setAsel, setViewPosition, viewPositionSelector } from "~redux/slices/edstSlice";
import type { AircraftMenuView } from "@poscon/shared-frontend";
import { AircraftMenuComponentMap, resetViewMenuFields, useOnUnmount } from "@poscon/shared-frontend";
import { layerZIndexMap } from "~/layerZIndexMap";

const AircraftMenu = ({ fpId, view }: { fpId: FlightplanId; view: AircraftMenuView }) => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiContainer | null>(null);
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const { startDrag, anyDragging } = useDragging(ref, view);

  const Component = AircraftMenuComponentMap[view];

  useOnUnmount(() => {
    dispatch(resetViewMenuFields(view));
  });

  return (
    <Component
      ref={ref}
      key={fpId}
      fpId={fpId}
      pos={pos}
      eventMode={anyDragging ? "none" : "static"}
      startDrag={startDrag}
      closeMenu={() => dispatch(setAsel(null))}
      openMenu={(menu) => {
        dispatch(setViewPosition({ view: menu, pos }));
        dispatch(setAsel({ menu, fpId, field: null }));
      }}
      zIndex={layerZIndexMap.prompts}
    />
  );
};

export const AltitudeMenu = ({ fpId }: { fpId: FlightplanId }) => (
  <AircraftMenu fpId={fpId} view="ALTITUDE_MENU" />
);

export const SpeedMenu = ({ fpId }: { fpId: FlightplanId }) => <AircraftMenu fpId={fpId} view="SPEED_MENU" />;

export const HeadingMenu = ({ fpId }: { fpId: FlightplanId }) => <AircraftMenu fpId={fpId} view="HEADING_MENU" />;

export const XResMenu = ({ fpId }: { fpId: FlightplanId }) => <AircraftMenu fpId={fpId} view="X_RES_MENU" />;

export const RouteMenu = ({ fpId }: { fpId: FlightplanId }) => <AircraftMenu fpId={fpId} view="ROUTE_MENU" />;

export const HoldMenu = ({ fpId }: { fpId: FlightplanId }) => <AircraftMenu fpId={fpId} view="HOLD_MENU" />;
