import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { AclRowField, AclSortMethod, PostingMode } from "@poscon/shared-types";
import type { RootState } from "~redux/store";

export type AclSortData = { selectedOption: AclSortMethod; sector: boolean };

type ToolsOptions = {
  displayCoordinationColumn: boolean;
  dropTrackDelete: boolean;
  iafDofManual: boolean;
  nonRvsmIndicator: boolean;
};

export type AclState = {
  sortData: AclSortData;
  postingMode: PostingMode;
  toolsOptions: ToolsOptions;
  hiddenColumns: AclRowField[];
};

const initialState: AclState = {
  sortData: { selectedOption: "ACL_ACID_SORT_METHOD", sector: false },
  postingMode: "AUTO",
  toolsOptions: {
    displayCoordinationColumn: false,
    dropTrackDelete: false,
    iafDofManual: false,
    nonRvsmIndicator: false,
  },
  hiddenColumns: [],
};

export const aclSlice = createSlice({
  name: "acl",
  initialState,
  reducers: {
    setAclState(state, action: PayloadAction<AclState>) {
      return action.payload;
    },
    setAclSort(state, action: PayloadAction<AclSortData>) {
      state.sortData = action.payload;
    },
    setAclPostingMode(state, action: PayloadAction<PostingMode>) {
      state.postingMode = action.payload;
    },
    updateToolsOptions(state, action: PayloadAction<ToolsOptions>) {
      state.toolsOptions = action.payload;
    },
    toggleAclHideColumn(state, action: PayloadAction<AclRowField | AclRowField[]>) {
      if (Array.isArray(action.payload)) {
        for (const column of action.payload) {
          const index = state.hiddenColumns.indexOf(column);
          if (index > -1) {
            state.hiddenColumns.splice(index, 1);
          } else {
            state.hiddenColumns.push(column);
          }
        }
      } else {
        const index = state.hiddenColumns.indexOf(action.payload);
        if (index > -1) {
          state.hiddenColumns.splice(index, 1);
        } else {
          state.hiddenColumns.push(action.payload);
        }
      }
    },
  },
});

export const { setAclState, setAclSort, setAclPostingMode, updateToolsOptions, toggleAclHideColumn } =
  aclSlice.actions;
export const reducer = aclSlice.reducer;

export const toolsOptionsSelector = (state: RootState) => state.acl.toolsOptions;
export const aclSortDataSelector = (state: RootState) => state.acl.sortData;
export const aclPostingModeSelector = (state: RootState) => state.acl.postingMode;
export const aclHiddenColumnsSelector = (state: RootState) => state.acl.hiddenColumns;
