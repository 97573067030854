import { Container } from "@pixi/react";
import type { Container as PixiContainer } from "pixi.js";
import type { ComponentPropsWithRef } from "react";
import React from "react";
import { useUiIsLocked } from "@poscon/shared-frontend";
import { useAnyDragging } from "contexts/dragContext";

export const useEventMode = () => {
  const uiIsLocked = useUiIsLocked();
  const { anyDragging } = useAnyDragging();

  return anyDragging || uiIsLocked ? "none" : "static";
};

type InteractionWrapperProps = {
  children?: React.ReactNode;
} & Omit<ComponentPropsWithRef<typeof Container>, "eventMode">;
export const InteractiveContainer = React.forwardRef<PixiContainer, InteractionWrapperProps>(
  ({ children, name, ...props }, ref) => {
    const eventMode = useEventMode();

    return (
      <Container {...props} name={name} sortableChildren ref={ref} eventMode={eventMode}>
        {children}
      </Container>
    );
  },
);
