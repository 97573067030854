import React, { useCallback, useRef } from "react";

import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { aselSelector, setAsel, setViewPosition, viewPositionSelector } from "~redux/slices/edstSlice";
import { openMenuThunk } from "~redux/thunks/openMenuThunk";
import { useDragging } from "hooks/useDragging";
import { useCenterCursor } from "@poscon/shared-frontend";
import type { EdstView } from "types/edstView";
import { eramHubConnection } from "~/eramHubConnection";
import { flightplanSelector } from "~redux/slices/aircraftSlice";
import { edstFpSelector } from "~redux/slices/edstFlightplanSlice";
import type { Container as PixiContainer } from "pixi.js";
import type { FlightplanId } from "@poscon/shared-types";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { MenuTitleBar } from "components/utils/MenuTitleBar";
import { useZIndex } from "hooks/useZIndex";
import { defaultTint, disabledTint, font2, font2Dimension } from "~/utils/constants";
import { layerZIndexMap } from "~/layerZIndexMap";
import { TableField } from "components/utils/TableField";
import type { RootThunkAction } from "~redux/store";
import { isDepRowField } from "@poscon/shared-types";

type PlanOptionItem = {
  y: number;
  text: string;
  disabled?: boolean;
  action?: (fpId: FlightplanId) => RootThunkAction;
};

const depItems: PlanOptionItem[] = [];
const aclItems: PlanOptionItem[] = [
  {
    y: 0,
    text: "Altitude...",
    action: () => (dispatch, getState) => {
      const pos = viewPositionSelector(getState(), "PLAN_OPTIONS");
      dispatch(setViewPosition({ view: "ALTITUDE_MENU", pos }));
      dispatch(setAsel({ ...aselSelector(getState())!, menu: "ALTITUDE_MENU" }));
    },
  },
  {
    y: font2Dimension.height + 4,
    text: "Speed...",
    action: () => (dispatch, getState) => {
      const pos = viewPositionSelector(getState(), "PLAN_OPTIONS");
      dispatch(setViewPosition({ view: "SPEED_MENU", pos }));
      dispatch(setAsel({ ...aselSelector(getState())!, menu: "SPEED_MENU" }));
    },
  },
  {
    y: (font2Dimension.height + 4) * 2,
    text: "Route...",
    action: () => (dispatch, getState) => {
      const pos = viewPositionSelector(getState(), "PLAN_OPTIONS");
      dispatch(setViewPosition({ view: "ROUTE_MENU", pos }));
      dispatch(setAsel({ ...aselSelector(getState())!, menu: "ROUTE_MENU" }));
    },
  },
  { y: (font2Dimension.height + 4) * 3, text: "Previous Route", disabled: true },
  { y: (font2Dimension.height + 4) * 4, text: "Stop Probe...", disabled: true },
  { y: (font2Dimension.height + 4) * 5, text: "Trial Restrictions...", disabled: true },
  { y: (font2Dimension.height + 4) * 6, text: "Plans", disabled: true },
  { y: (font2Dimension.height + 4) * 7, text: "Stop Probe...", disabled: true },
  { y: (font2Dimension.height + 4) * 8, text: "Keep", disabled: true },
  {
    y: (font2Dimension.height + 4) * 9,
    text: "Delete",
    action: (fpId) => (dispatch) => {
      eramHubConnection.emit("deleteEdstFp", fpId);
      dispatch(setAsel(null));
    },
  },
];

const width = font2Dimension.width * 24;

export const PlanOptions = ({ fpId }: { fpId: FlightplanId }) => {
  const dispatch = useRootDispatch();
  const asel = useRootSelector(aselSelector)!;
  const fp = useRootSelector((state) => flightplanSelector(state, fpId))!;
  const edstFp = useRootSelector((state) => edstFpSelector(state, fpId))!;

  const pos = useRootSelector((state) => viewPositionSelector(state, "PLAN_OPTIONS"));
  const ref = useRef<PixiContainer>(null);
  const zIndex = useZIndex("PLAN_OPTIONS", ref);
  const dep = isDepRowField(asel.field);
  useCenterCursor(ref);
  const { startDrag, anyDragging } = useDragging(ref, "PLAN_OPTIONS", "mouseup");

  const openMenu = useCallback(
    (menu: EdstView) => {
      dispatch(openMenuThunk(menu, ref.current, false, true));
    },
    [dispatch],
  );

  const onKeepClick = () => {
    eramHubConnection.updateEdstFpData(fp.id, { keep: true });
  };

  const items = dep ? depItems : aclItems;
  const height = font2Dimension.height * 4 + (items.at(-1)?.y ?? 0);

  return (
    <Container ref={ref} x={pos.x} y={pos.y} zIndex={layerZIndexMap.prompts} sortableChildren>
      <MenuTitleBar title="PLAN OPTIONS MENU" width={width} onTitleMouseDown={startDrag} view="PLAN_OPTIONS" />
      <Container y={font2Dimension.height + 3}>
        <Graphics
          draw={(graphics) =>
            graphics.lineStyle(1, 0xadadad).beginFill(0x000000).drawRect(0, 0, width, height).endFill()
          }
        />
        <BitmapText
          x={Math.floor(width / 2)}
          y={10}
          text={`${fp.cid}  ${fp.callsign}`}
          anchor={[0.5, 0]}
          style={{ fontName: font2, tint: defaultTint }}
        />
        <Container x={8} y={font2Dimension.height * 2 + 8}>
          {aclItems.map(({ y, text, disabled, action }) => (
            <TableField
              key={text}
              textX={2}
              y={y}
              width={width - 17}
              text={text}
              tint={disabled ? disabledTint : defaultTint}
              disabled={disabled}
              onmousedown={() => {
                if (action) {
                  dispatch(action(fpId));
                }
              }}
            />
          ))}
        </Container>
      </Container>
    </Container>
  );
  // return (
  //   <div
  //     className={clsx(planOptionStyles.root, { noPointerEvents: anyDragging })}
  //     ref={ref}
  //     style={{ ...pos, zIndex: 100 + zStack.indexOf("PLAN_OPTIONS") }}
  //     onmousedown={() =>
  //       zStack.indexOf("PLAN_OPTIONS") < zStack.length - 1 && dispatch(pushZStack("PLAN_OPTIONS"))
  //     }
  //   >
  //     <div className={clsx(optionStyles.header, { focused })} onmousedown={startDrag}>
  //       Plan Options Menu
  //     </div>
  //     <div className={planOptionStyles.body}>
  //       <div className={optionStyles.fidRow}>
  //         {fp.cid} {fp.callsign}
  //       </div>
  //       <PlanOptionsRow content="Altitude..." onmousedown={() => openMenu("ALTITUDE_MENU")} />
  //       {!dep && <PlanOptionsRow content="Speed..." disabled />}
  //       <PlanOptionsRow content="Route..." onmousedown={() => openMenu("ROUTE_MENU")} />
  //       <PlanOptionsRow
  //         content="Previous Route"
  //         onmousedown={() => openMenu("PREV_ROUTE_MENU")}
  //         disabled={!!fp.previousRoute}
  //       />
  //       {!dep && <PlanOptionsRow content={`${edstFp.probe ? "Stop" : "Resume"} Probe...`} disabled />}
  //       <PlanOptionsRow content={`Trial ${dep ? "Departure" : "Restrictions"}...`} disabled />
  //       {!dep && <PlanOptionsRow content="Plans" disabled />}
  //       <PlanOptionsRow content="Keep" onmousedown={onKeepClick} />
  //       <PlanOptionsRow
  //         content="Delete"
  //         onmousedown={() => {
  //           dispatch(setAsel(null));
  //           dispatch(closeAllMenus());
  //           // TODO: delete entry
  //           dispatch(closeView("PLAN_OPTIONS"));
  //         }}
  //       />
  //       <div className={optionStyles.bottomRow}>
  //         <div className={clsx(optionStyles.col, "right")}>
  //           <ExitButton onmousedown={() => dispatch(closeView("PLAN_OPTIONS"))} />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};
