import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { DepSortMethod, DepRowField, PostingMode } from "@poscon/shared-types";
import type { RootState } from "~redux/store";

export type DepState = {
  selectedSortOption: DepSortMethod;
  postingMode: PostingMode;
  hiddenColumns: DepRowField[];
};

const initialState: DepState = {
  selectedSortOption: "DEP_ACID_SORT_METHOD",
  postingMode: "AUTO",
  hiddenColumns: [],
};

export const depSlice = createSlice({
  name: "dep",
  initialState,
  reducers: {
    setDepState(state, action: PayloadAction<DepState>) {
      return action.payload;
    },
    setDepSort(state, action: PayloadAction<DepSortMethod>) {
      state.selectedSortOption = action.payload;
    },
    setDepPostingMode(state, action: PayloadAction<PostingMode>) {
      state.postingMode = action.payload;
    },
    toggleDepHideColumn(state, action: PayloadAction<DepRowField | DepRowField[]>) {
      if (Array.isArray(action.payload)) {
        for (const column of action.payload) {
          const index = state.hiddenColumns.indexOf(column);
          if (index > -1) {
            state.hiddenColumns.splice(index, 1);
          } else {
            state.hiddenColumns.push(column);
          }
        };
      } else {
        const index = state.hiddenColumns.indexOf(action.payload);
        if (index > -1) {
          state.hiddenColumns.splice(index, 1);
        } else {
          state.hiddenColumns.push(action.payload);
        }
      }
    },
  },
});

export const { toggleDepHideColumn, setDepState, setDepSort, setDepPostingMode } = depSlice.actions;
export const reducer = depSlice.reducer;
export const depSortOptionSelector = (state: RootState) => state.dep.selectedSortOption;
export const depPostingModeSelector = (state: RootState) => state.dep.postingMode;
export const depHiddenColumnsSelector = (state: RootState) => state.dep.hiddenColumns;
