import {
  ViewOptionContextProvider as TempViewOptionContextProvider,
  useViewOptions as useTempViewOptions,
} from "@poscon/shared-frontend";
import type { EdstView } from "types/edstView";
import type { ViewOptionState } from "~redux/slices/viewOptionSlice";

export const ViewOptionContextProvider = TempViewOptionContextProvider;

export const useViewOptions = <V extends EdstView = EdstView>(view?: V) => {
  return useTempViewOptions() as ViewOptionState[V];
};
