import { useRootDispatch, useRootSelector } from "~redux/hooks";
import type React from "react";
import { zStackSelector, pushZStack } from "~redux/slices/edstSlice";
import type { EdstView } from "types/edstView";
import type { DisplayObject } from "pixi.js";
import { usePixiMouseEventListener } from "@poscon/shared-frontend";

export const useZIndex = (view: EdstView, ref: React.RefObject<DisplayObject>) => {
  const dispatch = useRootDispatch();
  const zStack = useRootSelector(zStackSelector);
  usePixiMouseEventListener(
    () => {
      if (zStack.indexOf(view) !== zStack.length - 1) {
        dispatch(pushZStack(view));
      }
    },
    ref,
    true,
  );
  return zStack.indexOf(view);
};
