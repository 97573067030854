import { aircraftStateSelectors } from "@poscon/shared-frontend";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "~redux/store";
import type { FlightplanId } from "@poscon/shared-types";
import { aclAckListSelector, aclSpaListSelector, aclUnackListSelector } from "~redux/slices/edstFlightplanSlice";

export const {
  flightplansSelector,
  flightplanSelector,
  tracksSelector,
  trackSelector,
  sectorTracksSelector,
  sectorTrackSelector,
  coordinationDataSelector,
  trackCoordinationDataSelector,
  cpdlcSessionsSelector,
  cpdlcSessionSelector,
  trackFromFpIdSelector,
  flightplanFromCallsignSelector,
  hasCpdlcSessionSelector,
} = aircraftStateSelectors;

export const aselFlightplanSelector = (state: RootState) => {
  return state.edst.asel ? flightplanSelector(state, state.edst.asel.fpId) : null;
};

export const selectFlightplansById = createSelector(
  [flightplansSelector, (state: RootState, idList: FlightplanId[]) => idList],
  (flightplans, idList) => {
    return idList.map((id) => flightplans[id]!).filter((fp) => idList.includes(fp.id));
  },
);

export const anyHoldingSelector = createSelector(
  [aclAckListSelector, aclUnackListSelector, aclSpaListSelector, tracksSelector, coordinationDataSelector],
  (ackList, unAckList, spaList, tracks, coordinationData) => {
    return [...ackList, ...unAckList, ...spaList].some((id) => {
      const track = Object.values(tracks).find((track) => track.fpId === id);
      const trackCoordination = track ? coordinationData[track.id] : null;
      return !!trackCoordination?.holdAnnotations;
    });
  },
);

export const anyAssignedSpeedSelector = createSelector(
  [aclAckListSelector, aclUnackListSelector, aclSpaListSelector, tracksSelector, coordinationDataSelector],
  (ackList, unAckList, spaList, tracks, coordinationData) => {
    return [...ackList, ...unAckList, ...spaList].some((id) => {
      const track = Object.values(tracks).find((track) => track.fpId === id);
      const trackCoordination = track ? coordinationData[track.id] : null;
      return !!trackCoordination?.speed;
    });
  },
);

export const anyAssignedHeadingSelector = createSelector(
  [aclAckListSelector, aclUnackListSelector, aclSpaListSelector, tracksSelector, coordinationDataSelector],
  (ackList, unAckList, spaList, tracks, coordinationData) => {
    return [...ackList, ...unAckList, ...spaList].some((id) => {
      const track = Object.values(tracks).find((track) => track.fpId === id);
      const trackCoordination = track ? coordinationData[track.id] : null;
      return !!trackCoordination?.heading;
    });
  },
);
