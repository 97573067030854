import type { ComponentType } from "react";
import React, { useEffect, useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { eramFontDimensionMap, eramFontNameMap, useFocused } from "@poscon/shared-frontend";
import {
  pushZStack,
  setViewDimension,
  viewDimensionSelector,
  viewPositionSelector,
  zStackSelector,
} from "~redux/slices/edstSlice";
import type { EdstView } from "types/edstView";
import { useDragging } from "hooks/useDragging";
import { useFullscreen } from "hooks/useFullscreen";
import type {
  Container as PixiContainer,
  FederatedEventHandler,
  FederatedPointerEvent,
  Sprite as PixiSprite,
} from "pixi.js";
import { useEventListener, useWindowSize } from "usehooks-ts";
import { headerRowHeight } from "~/utils/constants";
import { Container, Graphics, Sprite } from "@pixi/react";
import { Rectangle, Texture } from "pixi.js";
import type { AnchorDirection } from "hooks/useResizable";
import { getCursorDirectionFromAnchor, resizeCursorCenterMap, useResizable } from "hooks/useResizable";

export type HeaderComponentProps = {
  focused: boolean;
  toggleFullscreen: () => void;
  width: number;
  startDrag: FederatedEventHandler;
};

export type BodyComponentProps = {
  width: number;
  height: number;
};

type FullscreenWindowProps = {
  view: EdstView;
  HeaderComponent: ComponentType<HeaderComponentProps>;
  BodyComponent: ComponentType<BodyComponentProps>;
};

const headerComponentHeight = eramFontDimensionMap[eramFontNameMap[2]].height + 2;

export const FullscreenView = React.memo(({ view, HeaderComponent, BodyComponent }: FullscreenWindowProps) => {
  const windowSize = useWindowSize();
  const dispatch = useRootDispatch();
  const ref = useRef<PixiContainer>(null);
  const maskRef = useRef<PixiSprite>(null);
  const focused = useFocused(ref);
  const zStack = useRootSelector(zStackSelector);
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const dim = useRootSelector((state) => viewDimensionSelector(state, view));
  const { startDrag, anyDragging } = useDragging(ref, view, "mouseup", false);
  const { isFullscreen, toggleFullscreen } = useFullscreen(view);
  const { startResize } = useResizable(ref, view);
  const toolbarPosition = useRootSelector((state) => state.edst.toolbarPosition);

  const x = isFullscreen ? 2 : pos.x;
  // eslint-disable-next-line no-nested-ternary
  const y = isFullscreen ? (toolbarPosition === "top" ? headerRowHeight + 4 : 2) : pos.y;
  const width = isFullscreen || dim.width === 0 ? windowSize.width - 3 : dim.width;
  const height = isFullscreen || dim.height === 0 ? windowSize.height - headerRowHeight - 6 : dim.height;

  const resizeHandler = (event: FederatedPointerEvent) => {
    if (zStack.indexOf(view) < zStack.length - 1 && !isFullscreen) {
      dispatch(pushZStack(view));
    }
    if (ref.current) {
      let anchorX: AnchorDirection = 0;
      let anchorY: AnchorDirection = 0;
      if (Math.abs(y + 1 - event.global.y) < 5) {
        anchorY = -1;
      }
      if (Math.abs(y + 1 + height - event.global.y) < 5) {
        anchorY = 1;
      }
      if (Math.abs(x + 1 - event.global.x) < 5) {
        anchorX = -1;
      }
      if (Math.abs(x + 1 + width - event.global.x) < 5) {
        anchorX = 1;
      }
      if (anchorX !== 0 || anchorY !== 0) {
        void startResize(event, anchorX, anchorY);
      }
    }
  };

  useEventListener("mousemove", (event) => {
    if (!anyDragging && ref.current) {
      let anchorX: AnchorDirection = 0;
      let anchorY: AnchorDirection = 0;
      if (Math.abs(y + 1 - event.pageY) < 5) {
        anchorY = -1;
      }
      if (Math.abs(y + 1 + height - event.pageY) < 5) {
        anchorY = 1;
      }
      if (Math.abs(x + 1 - event.pageX) < 5) {
        anchorX = -1;
      }
      if (Math.abs(x + 1 + width - event.pageX) < 5) {
        anchorX = 1;
      }
      const cursorDirection = getCursorDirectionFromAnchor(anchorX, anchorY);
      if (cursorDirection) {
        const cursorCenter = resizeCursorCenterMap[cursorDirection].join(" ");
        ref.current.cursor = `url(/cursors/EDSTResizeArrow${cursorDirection}.png) ${cursorCenter}, auto`;
      } else {
        ref.current.cursor = "";
      }
    }
  });

  useEffect(() => {
    if (dim.width === 0 || dim.height === 0) {
      dispatch(setViewDimension({ view, dim: { width, height } }));
    }
  }, [dim, dispatch, height, view, width]);

  return (
    <Container
      ref={ref}
      x={x}
      y={y}
      eventMode={anyDragging ? "none" : "static"}
      onmousedown={resizeHandler}
      zIndex={zStack.indexOf(view)}
      hitArea={new Rectangle(-2, -2, width + 4, height + 4)}
      sortableChildren
    >
      <Graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.lineStyle(1, 0xadadad, 1);
          graphics.drawRect(0, 0, width, height);
          graphics.lineStyle(3, 0x888888, 1);
          graphics.beginFill(0x000000, 1);
          graphics.drawRect(2, 2, width - 4, height - 4);
          graphics.endFill();
        }}
        eventMode="none"
        zIndex={0}
      />
      <Sprite texture={Texture.WHITE} width={width - 4} height={height - 4} ref={maskRef} eventMode="none" />
      <Container zIndex={1} x={2} y={3} mask={maskRef.current}>
        <HeaderComponent
          width={width - 6}
          focused={focused}
          toggleFullscreen={toggleFullscreen}
          startDrag={startDrag}
        />
        <BodyComponent width={width - 4} height={height - 4 - headerComponentHeight} />
      </Container>
    </Container>
  );
});
