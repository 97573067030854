import React from "react";
import { viewOptionSelector } from "~redux/slices/viewOptionSlice";
import { useRootSelector } from "~redux/hooks";
import { View } from "components/utils/View";
import { ViewOptionContextProvider } from "contexts/viewOptionContext";
import { CpdlcMsgOut as BaseCpdlcMsgOut } from "@poscon/shared-frontend";

const view = "CPDLC_MSGOUT";

export const CpdlcMsgOut = () => {
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <BaseCpdlcMsgOut ViewComponent={View} />
    </ViewOptionContextProvider>
  );
};
