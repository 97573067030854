import React from "react";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { defaultTint, font2, font2Dimension } from "~/utils/constants";
import type { ColorSource, FederatedEventHandler, Graphics as PixiGraphics } from "pixi.js";
import { Rectangle } from "pixi.js";
import { useFocused } from "@poscon/shared-frontend";

type TableFieldProps = {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  minWidth?: number;
  maxWidth?: number;
  fontName?: string;
  text?: string;
  tint?: ColorSource;
  fillColor?: ColorSource;
  onmousedown?: FederatedEventHandler;
  disabled?: boolean;
  borderTint?: ColorSource;
  hoverTint?: ColorSource;
  selected?: boolean;
  textX?: number;
  children?: React.ReactNode;
};

export const TableField = ({
  x,
  y,
  text: _text = "",
  textX,
  fontName = font2,
  minWidth = 0,
  width: _width = font2Dimension.width * _text.length + 3,
  maxWidth = _width,
  height = font2Dimension.height + 2,
  tint = defaultTint,
  fillColor = 0x000000,
  borderTint,
  hoverTint = 0xf0f0f0,
  selected,
  disabled,
  onmousedown,
  children,
}: TableFieldProps) => {
  const ref = React.useRef<PixiGraphics>(null);
  const focused = useFocused(ref);
  let text = fontName?.startsWith("eramText") ? _text.toUpperCase() : _text;
  const width = Math.min(Math.max(_width, minWidth), maxWidth);

  if (text.length * font2Dimension.width > width) {
    text = text.slice(0, Math.floor(width / font2Dimension.width));
  }

  return (
    <Container x={x ?? 0} y={y ?? 0} sortableChildren eventMode="static">
      <Graphics
        zIndex={0}
        ref={ref}
        eventMode={disabled ? "none" : "static"}
        draw={(graphics) => {
          graphics.clear();
          if (focused) {
            graphics.lineStyle(1, hoverTint);
          } else if (borderTint) {
            graphics.lineStyle(1, borderTint);
          }
          graphics
            .beginFill(selected ? tint : fillColor, selected ? 1 : 0)
            .drawRect(0, 0, width, height)
            .endFill();
        }}
        hitArea={new Rectangle(0, 0, width, height)}
        onmousedown={onmousedown}
        onrightdown={onmousedown}
      />
      {children}
      {!children && text && (
        <BitmapText
          eventMode="none"
          text={text}
          x={textX ?? Math.floor(width / 2)}
          anchor={textX !== undefined ? undefined : [0.5, 0]}
          y={2}
          tint={selected ? fillColor : tint}
          fontName={fontName}
          style={{ fontName, tint: selected ? fillColor : tint }}
        />
      )}
    </Container>
  );
};
