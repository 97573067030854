import React, { useMemo } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { aselSelector, setAsel } from "~redux/slices/edstSlice";
import {
  aclHiddenColumnsSelector,
  aclPostingModeSelector,
  toggleAclHideColumn,
  toolsOptionsSelector,
} from "~redux/slices/aclSlice";
import { Container } from "@pixi/react";
import { VCI_SYMBOL } from "@poscon/shared-types";
import { alertOrange, alertRed, alertYellow, font2Dimension } from "~/utils/constants";
import { eramCpdlcFontNameMap } from "@poscon/shared-frontend";
import { TableField } from "components/utils/TableField";
import { ListMapper, ListSeparator } from "components/utils/ListMapper";
import { AclRow } from "components/acl/AclRow";
import {
  aclAckListSelector,
  aclSpaListSelector,
  aclUnackListSelector,
  edstFlightplansSelector,
} from "~redux/slices/edstFlightplanSlice";
import { TableColumnPositionProvider } from "contexts/TableColumnPositionContext";
import {
  anyAssignedHeadingSelector,
  anyAssignedSpeedSelector,
  anyHoldingSelector,
} from "~redux/slices/aircraftSlice";
import { aclHeaderHeight } from "components/acl/AclHeader";

type AclTableProps = {
  width: number;
  height: number;
};

export const AclTable = ({ width, height }: AclTableProps) => {
  const dispatch = useRootDispatch();
  const edstFlightplans = useRootSelector(edstFlightplansSelector);
  const postingMode = useRootSelector(aclPostingModeSelector);
  const toolOptions = useRootSelector(toolsOptionsSelector);

  const ackList = useRootSelector(aclAckListSelector);
  const unackList = useRootSelector(aclUnackListSelector);
  const spaList = useRootSelector(aclSpaListSelector);

  const asel = useRootSelector(aselSelector);
  const anyHolding = useRootSelector(anyHoldingSelector);
  const anyAssignedHeading = useRootSelector(anyAssignedHeadingSelector);
  const anyAssignedSpeed = useRootSelector(anyAssignedSpeedSelector);
  const hiddenColumns = useRootSelector(aclHiddenColumnsSelector);

  const handleSlashClick = () => {
    if (hiddenColumns.includes("SPD_ACL_ROW_FIELD") && hiddenColumns.includes("HDG_ACL_ROW_FIELD")) {
      dispatch(toggleAclHideColumn(["SPD_ACL_ROW_FIELD", "HDG_ACL_ROW_FIELD"]));
      if (asel?.field === "SPD_ACL_ROW_FIELD" || asel?.field === "HDG_ACL_ROW_FIELD") {
        dispatch(setAsel(null));
      }
    } else {
      if (!hiddenColumns.includes("HDG_ACL_ROW_FIELD")) {
        dispatch(toggleAclHideColumn("HDG_ACL_ROW_FIELD"));
        if (asel?.field === "HDG_ACL_ROW_FIELD") {
          dispatch(setAsel(null));
        }
      }
      if (!hiddenColumns.includes("SPD_ACL_ROW_FIELD")) {
        dispatch(toggleAclHideColumn("SPD_ACL_ROW_FIELD"));
        if (asel?.field === "SPD_ACL_ROW_FIELD") {
          dispatch(setAsel(null));
        }
      }
    }
  };

  const vciWidth = font2Dimension.width + 2;
  const alertWidth = font2Dimension.width + 6;
  const flidWidth = font2Dimension.width * 21 + 2;
  const paWidth = font2Dimension.width * 3 + 2;
  const hotBoxWidth = font2Dimension.width * 2 + 8;
  const typeWidth = hiddenColumns.includes("TYPE_ACL_ROW_FIELD")
    ? font2Dimension.width * 2 + 4
    : font2Dimension.width * 7 + 4;
  const altWidth = font2Dimension.width * 8 + 4;
  const codeWidth = hiddenColumns.includes("CODE_ACL_ROW_FIELD")
    ? font2Dimension.width * 2 + 4
    : font2Dimension.width * 6 + 4;
  const hdgWidth = hiddenColumns.includes("HDG_ACL_ROW_FIELD")
    ? font2Dimension.width * 2 + 1
    : font2Dimension.width * 4 + 1;
  const slashWidth = font2Dimension.width + 1;
  const spdWidth = hiddenColumns.includes("SPD_ACL_ROW_FIELD")
    ? font2Dimension.width * 3 + 2
    : font2Dimension.width * 5 + 2;
  const remarksToggleBoxWidth = font2Dimension.width + 4;
  const holdIndicatorWidth = anyHolding ? font2Dimension.width + 4 : 0;

  const vciX = 2;
  const alertRedX = font2Dimension.width * 2 + 2;
  const alertYellowX = alertRedX + alertWidth;
  const alertOrangeX = alertYellowX + alertWidth;
  const flidX = alertOrangeX + alertWidth + font2Dimension.width * 2 + 4;
  const paX = flidX + flidWidth + 4;
  const typeX = paX + paWidth + 4 + hotBoxWidth;
  const altX = typeX + typeWidth + 4;
  const codeX = altX + altWidth + 4;
  const hdgX = codeX + codeWidth + 4;
  const slashX = hdgX + hdgWidth + 2;
  const spdX = slashX + slashWidth + 2;
  const remarksToggleBoxX = spdX + spdWidth + 4;
  const holdIndicatorX = remarksToggleBoxX + remarksToggleBoxWidth + 4;
  const routeX = holdIndicatorX + holdIndicatorWidth + 4;
  const routeWidth = width - routeX - 10;

  const tableColumnPositions = useMemo(
    () => ({
      positions: {
        VCI: vciX,
        ALERT_RED: alertRedX,
        ALERT_YELLOW: alertYellowX,
        ALERT_ORANGE: alertOrangeX,
        FID_ACL_ROW_FIELD: flidX,
        PA_ACL_ROW_FIELD: paX,
        TYPE_ACL_ROW_FIELD: typeX,
        ALT_ACL_ROW_FIELD: altX,
        CODE_ACL_ROW_FIELD: codeX,
        HDG_ACL_ROW_FIELD: hdgX,
        SPD_ACL_ROW_FIELD: spdX,
        REMARKS_TOGGLE_BOX: remarksToggleBoxX,
        HOLD_INDICATOR: holdIndicatorX,
        ROUTE_ACL_ROW_FIELD: routeX,
      },
      widths: {
        VCI: vciWidth,
        ALERT_RED: alertWidth,
        ALERT_YELLOW: alertWidth,
        ALERT_ORANGE: alertWidth,
        FID_ACL_ROW_FIELD: flidWidth,
        PA_ACL_ROW_FIELD: paWidth,
        TYPE_ACL_ROW_FIELD: typeWidth,
        ALT_ACL_ROW_FIELD: altWidth,
        CODE_ACL_ROW_FIELD: codeWidth,
        HDG_ACL_ROW_FIELD: hdgWidth,
        SPD_ACL_ROW_FIELD: spdWidth,
        REMARKS_TOGGLE_BOX: remarksToggleBoxWidth,
        HOLD_INDICATOR: holdIndicatorWidth,
        ROUTE_ACL_ROW_FIELD: routeWidth,
      },
      hiddenColumns,
    }),
    [
      alertOrangeX,
      alertRedX,
      alertWidth,
      alertYellowX,
      altWidth,
      altX,
      codeWidth,
      codeX,
      flidWidth,
      flidX,
      hdgWidth,
      hdgX,
      hiddenColumns,
      holdIndicatorWidth,
      holdIndicatorX,
      paWidth,
      paX,
      remarksToggleBoxWidth,
      remarksToggleBoxX,
      routeWidth,
      routeX,
      spdWidth,
      spdX,
      typeWidth,
      typeX,
      vciWidth,
    ],
  );

  const spaListHeight =
    (spaList.length + spaList.map((e) => edstFlightplans[e]?.showFreeText).filter(Boolean).length) *
    (font2Dimension.height + 6) +
    2 * (Math.floor(spaList.length / 3) + 1);

  const ackListHeight =
    (ackList.length + ackList.map((e) => edstFlightplans[e]?.showFreeText).filter(Boolean).length) *
    (font2Dimension.height + 6) +
    2 * (Math.floor(ackList.length / 3) + 1);

  return (
    <Container x={6} y={aclHeaderHeight + 2}>
      <Container>
        <TableField x={vciX} fontName={eramCpdlcFontNameMap[2]} text={VCI_SYMBOL} disabled />
        <TableField x={alertRedX} text="R" tint={alertRed} disabled />
        <TableField x={alertYellowX} text="Y" tint={alertYellow} disabled />
        <TableField x={alertOrangeX} text="A" tint={alertOrange} disabled />
        <TableField x={flidX} text="FLIGHT ID" disabled />
        <TableField x={paX} text="PA" />
        <TableField
          x={typeX}
          text={hiddenColumns.includes("TYPE_ACL_ROW_FIELD") ? "T" : "TYPE"}
          onmousedown={() => dispatch(toggleAclHideColumn("TYPE_ACL_ROW_FIELD"))}
        />
        <TableField x={altX} text="ALT." />
        <TableField
          x={codeX}
          text={hiddenColumns.includes("CODE_ACL_ROW_FIELD") ? "C" : "CODE"}
          onmousedown={() => dispatch(toggleAclHideColumn("CODE_ACL_ROW_FIELD"))}
        />
        <TableField
          x={hdgX}
          text={hiddenColumns.includes("HDG_ACL_ROW_FIELD") ? `${anyAssignedHeading ? "*" : " "}H` : " HDG"}
          textX={0}
          width={3 + font2Dimension.width * (hiddenColumns.includes("HDG_ACL_ROW_FIELD") ? 2 : 4)}
          onmousedown={() => dispatch(toggleAclHideColumn("HDG_ACL_ROW_FIELD"))}
        />
        <TableField x={slashX} text="/" onmousedown={handleSlashClick} />
        <TableField
          x={spdX}
          text={hiddenColumns.includes("SPD_ACL_ROW_FIELD") ? `S${anyAssignedSpeed ? "*" : ""}` : "SPD "}
          textX={0}
          width={3 + font2Dimension.width * (hiddenColumns.includes("SPD_ACL_ROW_FIELD") ? 2 : 4)}
          onmousedown={() => dispatch(toggleAclHideColumn("SPD_ACL_ROW_FIELD"))}
        />
        <TableField x={routeX} text="ROUTE" disabled />
      </Container>
      <TableColumnPositionProvider value={tableColumnPositions}>
        <Container y={font2Dimension.width + 10}>
          <ListMapper
            key="aclSpaList"
            list={spaList}
            Component={AclRow}
            width={width - 12}
            edstFlightplans={edstFlightplans}
          />
          <ListSeparator y={spaListHeight} width={width - 12} />
          <ListMapper
            key="aclAckList"
            y0={spaListHeight + 4}
            list={ackList}
            Component={AclRow}
            width={width - 12}
            edstFlightplans={edstFlightplans}
          />
          {postingMode === "MANUAL" && (
            <>
              <ListSeparator y={spaListHeight + 4 + ackListHeight} width={width - 12} />
              <ListMapper
                key="aclUnAckList"
                y0={spaListHeight + 4 + ackListHeight + 4}
                list={unackList}
                Component={AclRow}
                width={width - 12}
                edstFlightplans={edstFlightplans}
              />
            </>
          )}
        </Container>
      </TableColumnPositionProvider>
    </Container>
  );
};
