import type React from "react";
import { useState } from "react";
import { useInterval } from "usehooks-ts";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { trialPlanQueueSelector } from "~redux/slices/trailPlanSlice";
import { aselSelector } from "~redux/slices/edstSlice";
import type { TrialPlan } from "@poscon/shared-types";

const formatTime = (expirationTime: number, currentTime: number) => {
  const max = Math.max(expirationTime - currentTime, 0);
  return `${Math.floor(max / 60).toString()}:${Math.floor(max % 60)
    .toString()
    .padStart(2, "0")}`;
};

type PlansDisplayRowProps = {
  plan: TrialPlan;
  index: number;
  selected: boolean;
};
const PlansDisplayRow = ({ plan, index, selected }: PlansDisplayRowProps) => {
  // TODO: implement
  return null;
};

export const PlansDisplayTable = () => {
  const dispatch = useRootDispatch();
  const planQueue = useRootSelector(trialPlanQueueSelector);
  const asel = useRootSelector(aselSelector);

  const [time, setTime] = useState(Date.now() / 1000);
  useInterval(() => setTime(Date.now() / 1000), 1000);

  const handleMouseDown = (event: React.MouseEvent, index: number) => {
    event.preventDefault();
    switch (event.button) {
      case 0:
        // dispatch(setSelectedPlanIndex(selectedPlanIndex === index ? null : index));
        break;
      case 2:
        // dispatch(removePlanThunk(index));
        // TODO: implement remove plan
        break;
      default:
        break;
    }
  };

  return null;
  // return (
  //   <div className={pdStyles.body}>
  //     {planQueue?.map((p, i) => (
  //       // eslint-disable-next-line react/no-array-index-key
  //       <div className={tableStyles.row} key={i}>
  //         <div
  //           className={clsx(pdStyles.fixCol, { selected: selectedPlanIndex === i })}
  //           onmousedown={(event) => handleMouseDown(event, i)}
  //         >
  //           {p.cid} {p.fpId}
  //         </div>
  //         <div className={pdStyles.col}>{p.commandString.toUpperCase()}</div>
  //         <div className={clsx(pdStyles.col2, { expired: p.expirationTime - time < 0 })}>
  //           {formatTime(p.expirationTime, time)}
  //         </div>
  //       </div>
  //     ))}
  //   </div>
  // );
};
